<template>
	<div class="calendar-print" :class="{ 'with-calendar-gaps': showGaps }">
		<div class="calendar-print-header">
			<h2 class="calendar-print-title">
				{{ title }} - {{ calendarName }}
			</h2>
			<div class="calendar-print-user">
				Printed by: {{ $store.state.auth.user.first_name }}
				{{ $store.state.auth.user.last_name }}
				- {{ now }}
			</div>
		</div>
		<div class="calendar-header">
			<div class="calendar-header-actions">
				<button
					class="btn btn-outlined btn-outline-material calendar-create-button"
					type="button"
					@click.prevent="onCreateEvent"
				>
					<div class="btn-ripple"></div>
					<span class="btn-label">New Event</span>
				</button>
				<button
					class="btn btn-sm calendar-create-icon"
					type="button"
					@click.prevent="onCreateEvent"
				>
					<div class="btn-ripple"></div>
					<font-awesome-icon
						class="btn-icon"
						:icon="['fal', 'plus']"
					/>
				</button>
				<transition name="fade">
					<button
						v-if="clipboard && currentView !== 'list'"
						class="btn btn-outlined btn-sm btn-outline-success mr-auto whitespace-no-wrap"
						type="button"
						data-tooltip="Double click on a day to paste"
						@click.prevent="clipboard = null"
					>
						<div class="btn-ripple"></div>
						<font-awesome-icon
							class="btn-icon"
							:icon="['fal', 'check']"
						/>
						<span class="btn-label">Copied</span>
					</button>
				</transition>
			</div>
			<div class="calendar-header-center">
				<button
					class="calendar-header-btn previous"
					@click.prevent="onPreviousClick"
				>
					<font-awesome-icon
						fixed-width
						:icon="['far', 'arrow-alt-square-left']"
						aria-hidden="true"
					/>
				</button>
				<h2 class="calendar-header-title">{{ title }}</h2>
				<button
					class="calendar-header-btn next"
					@click.prevent="onNextClick"
				>
					<font-awesome-icon
						fixed-width
						:icon="['far', 'arrow-alt-square-right']"
						aria-hidden="true"
					/>
				</button>
			</div>
			<div class="calendar-header-views">
				<button
					class="calendar-header-view-btn"
					:class="{ active: currentView === 'month' }"
					data-tooltip="Month View"
					aria-label="Month View"
					@click.prevent="onMonthView"
				>
					<font-awesome-icon
						class="text-xl"
						fixed-width
						:icon="['fal', 'calendar-alt']"
						aria-hidden="true"
					/>
				</button>
				<button
					class="calendar-header-view-btn"
					:class="{ active: currentView === 'week' }"
					data-tooltip="Week View"
					aria-label="Week View"
					@click.prevent="onWeekView"
				>
					<font-awesome-icon
						class="text-xl"
						fixed-width
						:icon="['fal', 'calendar-week']"
						aria-hidden="true"
					/>
				</button>
				<button
					class="calendar-header-view-btn"
					:class="{ active: currentView === 'day' }"
					data-tooltip="Day View"
					aria-label="Day View"
					@click.prevent="onDayView"
				>
					<font-awesome-icon
						class="text-xl"
						fixed-width
						:icon="['fal', 'calendar-day']"
						aria-hidden="true"
					/>
				</button>
				<button
					class="calendar-header-view-btn"
					:class="{ active: currentView === 'list' }"
					data-tooltip="List View"
					aria-label="List View"
					@click.prevent="onListView"
				>
					<font-awesome-icon
						class="text-xl"
						fixed-width
						:icon="['fal', 'clipboard-list']"
						aria-hidden="true"
					/>
				</button>
			</div>
		</div>
		<context-menu
			:event-id="clipboard"
			@copy="onCopyEvent"
			@paste="onPasteEvent"
		/>

		<full-calendar
			v-if="config"
			id="calendar"
			ref="calendar"
			class="h-full"
			:options="config"
		>
			<template #eventContent="{ event }">
				<calendar-event :event="event" />
			</template>
		</full-calendar>
		<create-event-panel
			:show="panels.create"
			@close="panels.create = false"
		/>

		<edit-event-panel
			:id="eventId"
			:show="panels.edit"
			:active-date="activeDate"
			@close="onEditPanelClose"
		/>

		<confirmation-dialog
			:show="confirmDrop"
			@confirmed="onDropConfirm"
			@cancel="onDropCancel"
		>
			<template #title>
				Warning
			</template>
			Moving this event will remove all associated recurring events from
			the calendar.
		</confirmation-dialog>
	</div>
</template>

<script>
import moment from 'moment'
import Event from 'App/Models/Event'
import FullCalendar from '@fullcalendar/vue'
import { mapActions, mapGetters } from 'vuex'
import ContextMenu from '@/components/ContextMenu'
import EventDuplicator from 'App/Calendar/EventDuplicator'
import FullCalendarEvent from 'App/Calendar/FullCalendarEvent'
import CalendarEvent from '@/components/FullCalendar/Event.vue'
import FullCalendarConfig from 'App/Calendar/FullCalendarConfig'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import EditEventPanel from '@/components/Panels/Events/EditPanel.vue'
import CreateEventPanel from '@/components/Panels/Events/CreatePanel.vue'

// Plugins must be imported after core FullCalendar import
import browser from '@/app/Support/Browser'
import { Draggable } from '@fullcalendar/interaction'

/**
 * The default timeblock duration.
 *
 * @type {Number}
 */
const DEFAULT_TIME_BLOCK_DURATION = 120

/**
 * Constant representing the date time format.
 */
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CalendarEvent,
		ConfirmationDialog,
		ContextMenu,
		CreateEventPanel,
		EditEventPanel,
		FullCalendar,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The full calendar configuration options.
		 *
		 * @return {Object}
		 */
		config() {
			if (!this.fullCalendarConfig) {
				return
			}

			return this.fullCalendarConfig
				.setEvents(this.events)
				.setBusinessHours(this.timeblocks)
				.setDayMaxEventRows(this.dayMaxEventRows)
		},

		/**
		 * Get current calendar from store.
		 *
		 * @return {String}
		 */
		calendarName() {
			const calendar = this.findCalendar(parseInt(this.$route.params.id))

			if (!calendar) {
				return ''
			}

			return calendar.name
		},

		/**
		 * Get the current calendar's events.
		 *
		 * @return {Array}
		 */
		events() {
			const calendar_id = parseInt(this.$route.params.id)

			const events =
				this.findEventsByCalendarAndDate(
					calendar_id,
					this.startDate,
					this.endDate
				) || []

			return events.map(event => new FullCalendarEvent(event).toObject())
		},

		/**
		 * Get the timeblocks in the Full Calendar business hours format.
		 *
		 * @return {Array}
		 */
		timeblocks() {
			const blocks = this.getTimeblocksByCalendar(this.$route.params.id)

			return blocks.reduce((accu, block) => {
				const { start, end, days_of_the_week } = block

				if (start === end) {
					accu.push({
						daysOfWeek: days_of_the_week,
						startTime: '00:00',
						endTime: '24:00',
					})

					return accu
				}

				if (start.substring(0, 2) < end.substring(0, 2)) {
					accu.push({
						daysOfWeek: days_of_the_week,
						startTime: start.substring(0, 5),
						endTime: end.substring(0, 5),
					})

					return accu
				}

				accu.push({
					daysOfWeek: days_of_the_week,
					startTime: start.substring(0, 5),
					endTime: '24:00',
				})

				const daysOfWeek = days_of_the_week.map(day => {
					return day == 6 ? 0 : day + 1
				})

				accu.push({
					daysOfWeek,
					startTime: '00:00',
					endTime: end.substring(0, 5),
				})

				return accu
			}, [])
		},

		...mapGetters({
			hasOfficeManagerAccess: 'auth/hasOfficeManagerAccess',
			findCalendar: 'calendars/find',
			findEvent: 'events/find',
			findEventsByCalendarAndDate:
				'calendars/findEventsByCalendarAndDate',
			getTimeblocksByCalendar: 'timeBlocks/getByCalendarId',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the eventables depending on the selected type.
		 *
		 * @param {Object} event
		 * @param {String} type
		 * @param {Number} id
		 * @return {void}
		 */
		addEventables(event, type, ids) {
			switch (type) {
				case 'provider':
					event.providers = ids

					break
				case 'provider-group':
					event.provider_groups = ids

					break
				case 'linked-calendar':
					event.linked_calendars = ids

					break
			}

			return event
		},

		/**
		 * Get the full calendar API instance.
		 *
		 * @return {Calendar}
		 */
		calendar() {
			if (!this.$refs.calendar) {
				return
			}

			return this.$refs.calendar.getApi()
		},

		/**
		 * Trigger the emit fetch gaps function.
		 *
		 * @return {void}
		 */
		fetchGaps() {
			if (this.showGaps) {
				this.$emit('gaps:fetch')
			}
		},

		/**
		 * Determine if the calendar has gaps
		 *
		 * @param {String} date
		 * @return {Boolean}
		 */
		hasDayGap(date) {
			if (!this.gaps.length) {
				return false
			}

			const dayToCheck = moment(date)

			return this.gaps.some(day => dayToCheck.isSame(day, 'day'))
		},

		/**
		 * Add a css class to each day of the calendar.
		 *
		 * @param {Object} payload.date
		 * @param {Array}
		 */
		onDayCellClassNames({ date }) {
			if (this.hasDayGap(date)) {
				return ['has-calendar-gap']
			}

			return []
		},

		/**
		 * Emit the given calendar dates.
		 *
		 * @return {void}
		 */
		emitCalendarDates() {
			this.$emit('calendar:dates', {
				starts: this.startDate,
				ends: this.endDate,
			})
		},

		/**
		 * Handle the event content full-calendar hook.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		async eventDrop(info) {
			this.pendingDrop = info

			const event = new Event(this.findEvent(info.event.id))

			const calendarDate = moment(info.oldEvent.start).utc()
			const eventDate = moment.utc(event.starts_at)

			info.event.is_child =
				!eventDate.isSame(calendarDate, 'day') && event.is_recurring

			if (!info.event.is_child && event.is_recurring) {
				return (this.confirmDrop = true)
			}

			this.onEventChange(info)
		},

		/**
		 * Triggered when the user mouses over an event.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		eventMouseEnter(info) {
			info.el.addEventListener('contextmenu', event => {
				this.mouseListener = this.$app.emit('event:contextmenu', {
					info,
					event,
				})
			})
		},

		/**
		 * Triggered when the user mouses out of an event.
		 *
		 * @param {Object} el
		 * @return {void}
		 */
		eventMouseLeave({ el }) {
			el.removeEventListener('contextmenu', this.mouseListener)
		},

		/**
		 * Handle the calendar resize event.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		async eventResize(info) {
			await this.onEventChange(info)
		},

		/**
		 * Get the calendar events.
		 *
		 * @return {void}
		 */
		async getEvents() {
			try {
				await this.fetchEvents({
					calendar: this.$route.params.id,
					start: this.startDate.format('YYYY-MM-DD'),
					end: this.endDate.format('YYYY-MM-DD'),
				})
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		/**
		 * Handle the update child event occurrences.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		async handleChildEventOccurrenceUpdate(info) {
			const event = new Event(this.findEvent(info.event.id))
			const start = moment(info.event.start).format(DATETIME_FORMAT)

			try {
				const response = await EventDuplicator.forEventId(info.event.id)
					.forCalendar(event.calendar_id)
					.onDate(start)
					.withException(info.oldEvent.start)
					.duplicate()

				await this.getEvents()

				this.$alert.response(response)
			} catch (e) {
				info.revert()

				this.handleErrorResponse(e)
			}
		},

		/**
		 * Handle the error response.
		 *
		 * @param {Object} error
		 * @return {void}
		 */
		handleErrorResponse(error) {
			let message = 'Something went wrong! The event was not updated.'

			if (error?.hasErrors && error.hasErrors()) {
				message =
					error.getFirstError('duration') ||
					error.getFirstError('starts_at') ||
					message
			}

			this.$alert.error(message)
		},

		/**
		 * Handle the on copy event.
		 *
		 * @return {void}
		 */
		onCopyEvent(id) {
			this.clipboard = parseInt(id)
		},

		/**
		 * Handle the on create event click event.
		 *
		 * @return {void}
		 */
		onCreateEvent() {
			this.panels.create = true
		},

		/**
		 * Handle the event create panel close event.
		 *
		 * @return {void}
		 */
		onCreatePanelClose() {
			this.panels.create = false

			this.fetchGaps()
		},

		/**
		 * Handle the on date click event.
		 *
		 * @param {Object} event
		 * @return {void}
		 */
		onDateClick(event) {
			this.selectedDay = moment(event.date).format('YYYY-MM-DD')

			this.$app.emit('day:contextmenu', event)
		},

		/**
		 * Handle the on day click view event.
		 *
		 * @return {void}
		 */
		async onDayView() {
			if (this.currentView !== 'day') {
				this.currentView = 'day'

				this.calendar().changeView('timeGridDay')

				this.updateCalendarTitle()

				this.updateDatesInterval()

				this.toggleGaps()

				this.emitCalendarDates()

				await this.getEvents()
			}
		},

		/**
		 * Handle an event being dropped onto the calendar.
		 *
		 * @param {Object} $event
		 * @return {void}
		 */
		async onDrop($event) {
			const data = JSON.parse($event.draggedEl.dataset.event)

			const start = moment(
				$event.allDay ? $event.date.setHours(12) : $event.date
			).utc()

			if (start.isBefore()) {
				return this.$alert.error(
					'The event cannot start more than 1 hour ago.'
				)
			}

			if (data && data.id) {
				const calendarId = parseInt(this.$route.params.id)

				const event = {
					calendar_id: calendarId,
					recurrence: null,
					duration: DEFAULT_TIME_BLOCK_DURATION,
					starts_at: start.format(DATETIME_FORMAT),
					event_type_id: data.type.id,
					use_default_times: $event.allDay,
					use_time_blocks: !$event.allDay,
				}

				try {
					const response = await this.createEvent({
						calendarId,
						event: this.addEventables(event, data.type.name, [
							data.id,
						]),
					})

					this.eventId = response.get('data', {}).id

					this.panels.edit = true

					this.fetchGaps()
				} catch (e) {
					this.$alert.response(e)
				}
			}
		},

		/**
		 * Handle the on drop cancel event.
		 *
		 * @return {void}
		 */
		onDropCancel() {
			try {
				this.pendingDrop.revert()
			} catch (e) {
				console.error(e)
			}

			this.confirmDrop = false

			this.pendingDrop = null
		},

		/**
		 * Handle the on drop confirm event.
		 *
		 * @return {void}
		 */
		onDropConfirm() {
			this.onEventChange({ ...this.pendingDrop })

			this.confirmDrop = false

			this.pendingDrop = null
		},

		/**
		 * Handle the event edit panel close event.
		 *
		 * @return {void}
		 */
		onEditPanelClose() {
			this.eventId = null
			this.panels.edit = false

			this.fetchGaps()
		},

		/**
		 * Handle the event click event.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		onEventClick(info) {
			this.activeDate = moment(info.event.start)
			this.eventId = info.event.id

			this.panels.edit = true
		},

		/**
		 * Handle the gaps change event.
		 *
		 * @return {void}
		 */
		onGapsChange() {
			this.refreshCalendar()
		},

		/**
		 * Handle the on list click view event.
		 *
		 * @return {void}
		 */
		async onListView() {
			if (this.currentView !== 'list') {
				this.currentView = 'list'

				this.calendar().changeView('listWeek')

				this.updateCalendarTitle()

				this.updateDatesInterval()

				this.toggleGaps()

				this.emitCalendarDates()

				await this.getEvents()
			}
		},

		/**
		 * Handle the on month click view event.
		 *
		 * @return {void}
		 */
		async onMonthView() {
			if (this.currentView !== 'month') {
				this.currentView = 'month'

				this.calendar().changeView('dayGridMonth')

				this.updateCalendarTitle()

				this.updateDatesInterval()

				this.toggleGaps()

				this.emitCalendarDates()

				await this.getEvents()
			}
		},

		/**
		 * Handle the on nav link day click event.
		 *
		 * @param {Object} date
		 * @return {void}
		 */
		onNavLinkDayClick(date) {
			this.calendar().gotoDate(date)

			this.onDayView()
		},

		/**
		 * Get next calendar events.
		 *
		 * @return {void}
		 */
		async onNextClick() {
			this.calendar().next()

			this.updateCalendarTitle()

			this.updateDatesInterval()

			this.toggleGaps()

			this.emitCalendarDates()

			await this.getEvents()
		},

		/**
		 * Handle the paste event click event.
		 *
		 * @return {void}
		 */
		async onPasteEvent() {
			if (!this.selectedDay || !this.clipboard) {
				return
			}

			try {
				await EventDuplicator.forEventId(this.clipboard)
					.forCalendar(this.$route.params.id)
					.onDate(this.selectedDay)
					.duplicate()

				this.fetchGaps()
			} catch (e) {
				this.$alert.response(e)
			}
		},

		/**
		 * Get previous calendar events.
		 *
		 * @return {void}
		 */
		async onPreviousClick() {
			this.calendar().prev()

			this.updateCalendarTitle()

			this.updateDatesInterval()

			this.toggleGaps()

			this.emitCalendarDates()

			await this.getEvents()
		},

		/**
		 * Get the selected day.
		 *
		 * @param {String} payload.start
		 * @return {void}
		 */
		onSelectDay(event) {
			// The selected day will always be in local time b/c it is just date.
			this.selectedDay = moment(event.start).format('YYYY-MM-DD')

			this.$app.emit('day:contextmenu', event)
		},

		/**
		 * Handle the on today click event.
		 *
		 * @return {void}
		 */
		async onTodayClick() {
			this.calendar().today()

			this.updateCalendarTitle()

			this.updateDatesInterval()

			await this.getEvents()
		},

		/**
		 * Handle the on week click view event.
		 *
		 * @return {void}
		 */
		async onWeekView() {
			if (this.currentView !== 'week') {
				this.currentView = 'week'

				this.calendar().changeView('timeGridWeek')

				this.updateCalendarTitle()

				this.updateDatesInterval()

				this.toggleGaps()

				this.emitCalendarDates()

				await this.getEvents()
			}
		},

		/**
		 * Handle the window resize to change the calendar view.
		 *
		 * @return {void}
		 */
		onWindowResize() {
			browser.isSmallScreen() ? this.onMonthView() : this.onListView()
		},

		/**
		 * Update the calendar's title.
		 *
		 * @return {void}
		 */
		updateCalendarTitle() {
			this.title = this.calendar().currentData.viewTitle
		},

		/**
		 * Update the calendar's dates interval.
		 *
		 * @return {void}
		 */
		updateDatesInterval() {
			const { activeEnd, activeStart } = this.calendar().view

			this.endDate = moment.utc(activeEnd)
			this.startDate = moment.utc(activeStart)
		},

		/**
		 * Get the time offset in minutes.
		 *
		 * @param {Moment} start
		 * @param {Moment} end
		 * @return  {Number}
		 */
		getOffset(start, end) {
			const startsAt = moment
				.utc(start, DATETIME_FORMAT)
				.local()
				.clone()
			const endsAt = moment
				.utc(end, DATETIME_FORMAT)
				.local()
				.clone()

			return startsAt.utcOffset() - endsAt.utcOffset()
		},

		/**
		 * Update the given event.
		 *
		 * @param {Object} info
		 * @return {void}
		 */
		async onEventChange(info) {
			const event = new Event(this.findEvent(info.event.id))

			if (info.event.is_child) {
				return this.handleChildEventOccurrenceUpdate(info)
			}

			const start = moment.utc(info.event.start)
			const end = moment.utc(info.event.end)
			const duration =
				end.diff(start, 'minutes') + this.getOffset(start, end)

			const updated = {
				calendar_id: event.calendar_id,
				starts_at: start.format(DATETIME_FORMAT),
				recurrence: info.event.is_child ? null : [],
				duration,
				event_type_id: event.event_type_id,
			}

			try {
				const ids = event.eventables.map(eventable => eventable.id)

				const response = await this.updateEvent({
					calendar: event.calendar_id,
					eventId: event.id,
					event: this.addEventables(updated, event.type.name, ids),
				})

				this.fetchGaps()

				this.$alert.response(response)
			} catch (e) {
				info.revert()

				this.handleErrorResponse(e)
			}
		},

		/**
		 * Refresh the full calendar instance.
		 *
		 * @return {void}
		 */
		refreshCalendar() {
			if (!this.calendar()) {
				return
			}

			this.calendar().destroy()
			this.calendar().render()
		},

		/**
		 * Toggle the gaps when that feature is turned on.
		 *
		 * @return {void}
		 */
		toggleGaps() {
			if (this.showGaps) {
				this.$emit('gaps:toggle')
			}
		},

		...mapActions({
			createEvent: 'events/create',
			fetchEvents: 'events/get',
			updateEvent: 'events/update',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current partner's schedule calendars.
		 *
		 * @type {Array}
		 */
		calendars: {
			type: Array,
			default() {
				return []
			},
		},

		/**
		 * The max number of event to display in the calendar.
		 *
		 * @type {Number}
		 */
		dayMaxEventRows: {
			type: Number,
			default: 4,
		},

		/**
		 * The found gaps to be display in the calendar.
		 *
		 * @type {Array}
		 */
		gaps: {
			type: Array,
			default() {
				return []
			},
		},

		/**
		 * Show the calendar's coverage gaps.
		 *
		 * @type {Boolean}
		 */
		showGaps: {
			type: Boolean,
			default: false,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the gaps property for changes.
		 *
		 * @return {void}
		 */
		gaps: {
			handler: 'onGapsChange',
			immediate: true,
		},

		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner === from.params.partner) {
				this.$emit('calendar:dates', {
					starts: this.startDate,
					ends: this.endDate,
				})

				this.getEvents()
			}
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app.off('schedules:today', this.onTodayClick)

		if (this.draggable) {
			this.draggable.destroy()
		}
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.fullCalendarConfig = new FullCalendarConfig()
			.setDateClick(this.onDateClick)
			.setDayCellClassNames(this.onDayCellClassNames)
			.setDrop(this.onDrop)
			.setEventClick(this.onEventClick)
			.setEventMouseEnter(this.eventMouseEnter)
			.setEventMouseLeave(this.eventMouseLeave)
			.setEventDrop(this.eventDrop)
			.setEventResize(this.eventResize)
			.setNavLinkDayClick(this.onNavLinkDayClick)
			.setSelect(this.onSelectDay)
			.setWindowResize(this.onWindowResize)

		this.$app.on('schedules:today', this.onTodayClick)
	},

	/**
	 * The component's mounted lifecycle hook.
	 *
	 * @return {void}
	 */
	async mounted() {
		const { currentData, view } = this.calendar()

		this.title = currentData.viewTitle

		this.startDate = moment.utc(view.activeStart)

		this.endDate = moment.utc(view.activeEnd)

		await this.getEvents()

		if (this.$parent.$refs.providers) {
			this.draggable = new Draggable(this.$parent.$refs.providers, {
				itemSelector: '.draggable-card',
			})
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			activeDate: null,
			clipboard: null,
			confirmDrop: false,
			currentView: window.innerWidth > 576 ? 'month' : 'list',
			draggable: null,
			endDate: moment().endOf('month'),
			eventId: null,
			fullCalendarConfig: null,
			now: moment().format('MM/DD/YYYY hh:mm A'),
			mouseListener: null,
			panels: {
				create: false,
				edit: false,
			},
			pendingDrop: null,
			selectedDay: null,
			startDate: moment().startOf('month'),
			title: moment().format('MMMM YYYY'),
		}
	},
}
</script>
