import moment from 'moment'

/**
 * Base entity model.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Model {
	/**
	 * Create a new Model instance.
	 *
	 * @param {Object} data
	 */
	constructor(data = {}) {
		this.fill(data)
	}

	/**
	 * Get the model's castable attribute definitions.
	 *
	 * @return {Object}
	 */
	get casts() {
		return {}
	}

	/**
	 * Get the fillable attributes.
	 *
	 * @return {Array}
	 */
	get fillable() {
		return []
	}

	/**
	 * Hydrate the model with the given data.
	 *
	 * @param {Object} data
	 * @return {Event}
	 */
	fill(data) {
		for (let key in data) {
			this.setAttribute(key, data[key])
		}

		return this
	}

	/**
	 * Get an attribute's value by key name.
	 *
	 * @param {String} key
	 * @param {*} defaultValue
	 * @return {*}
	 */
	getAttribute(key, defaultValue = null) {
		if (this.hasCasts(key)) {
			return this.getRawValue(key)
		}

		return this[key] || defaultValue
	}

	/**
	 * Get an attribute's casted/final value.
	 *
	 * @param {String} key
	 * @param {*} value
	 * @return {*}
	 */
	getCastedValue(key, value) {
		if (!this.hasCasts(key)) {
			return value
		}

		let [type, ...format] = this.casts[key].split(':')

		format = format.join(':')

		switch (type) {
			case 'datetime':
				return moment.utc(value, format)
			case 'int':
				return parseInt(value) || null
			default:
				return value
		}
	}

	/**
	 * Get a raw attribute value converted into its uncasted form.
	 *
	 * @param {String} key
	 * @return {*}
	 */
	getRawValue(key) {
		let [type, format] = this.casts[key].split(/:(.+)/)

		switch (type) {
			case 'datetime':
				return this[key]
					.clone()
					.local()
					.format(format)
			default:
				return this[key]
		}
	}

	/**
	 * Check if the attribute's value should be cast.
	 *
	 * @param {String} key
	 * @return {Boolean}
	 */
	hasCasts(key) {
		return !!this.casts[key]
	}

	/**
	 * Check if the key can be set during mass assignment.
	 *
	 * @param {String} key
	 * @return {Boolean}
	 */
	isFillable(key) {
		return this.fillable.indexOf(key) > -1
	}

	/**
	 * Set an attribute's on the model.
	 *
	 * @param {String} key
	 * @param {*} value
	 * @return {Event}
	 */
	setAttribute(key, value) {
		if (!this.isFillable(key)) {
			return this
		}

		this[key] = this.getCastedValue(key, value)

		return this
	}

	/**
	 * Get the model as a plain attribute object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return this.fillable.reduce((accu, key) => {
			accu[key] = this.getAttribute(key)

			return accu
		}, {})
	}
}
