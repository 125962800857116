<template>
	<div class="table-responsive">
		<table class="table table-auto">
			<thead>
				<tr>
					<th>Starts at</th>
					<th>Remove</th>
				</tr>
			</thead>
			<tbody v-if="(exceptions || []).length">
				<tr v-for="exception in exceptions" :key="exception.id">
					<td class="whitespace-pre-wrap break-words">
						{{ exception.starts_at | toDateTimeFormat('MM/DD/YY') }}
					</td>
					<td>
						<button
							type="button"
							aria-label="Remove row"
							class="btn btn-sm"
							@click.prevent="onRemove(exception.id)"
						>
							<div class="btn-ripple"></div>
							<font-awesome-icon
								fixed-width
								:icon="['fal', 'trash-alt']"
								aria-hidden="true"
							/>
						</button>
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td class="text-center" colspan="4">
						No event exceptions have been created for this event.
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the event id.
		 *
		 * @return {Object}
		 */
		event() {
			return this.findEvent(this.eventId)
		},

		/**
		 * Get the event exceptions.
		 *
		 * @return {Array}
		 */
		exceptions() {
			if (!this.event) {
				return []
			}

			return this.event?.exceptions || []
		},

		...mapGetters({
			findEvent: 'events/find',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the remove click event.
		 *
		 * @return {void}
		 */
		async onRemove(id) {
			try {
				const response = await this.removeEventException({
					event: this.eventId,
					exception: id,
				})

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}
		},

		...mapActions({
			removeEventException: 'events/removeEventException',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EventExceptions',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event's id.
		 *
		 * @type {Number}
		 */
		eventId: {
			type: String,
			required: true,
		},
	},
}
</script>
