<template>
	<div class="days-of-week-container">
		<div
			v-for="day in daysOfWeek"
			:key="day.value"
			class="days-of-week-item"
			:class="{ active: days.includes(day.value) }"
			:aria-label="day.name"
			:aria-checked="days.includes(day.value)"
			tabindex="0"
			:title="day.name"
			role="checkbox"
			@click.prevent="onSelectDay(day.value)"
			@keydown.space.prevent="onSelectDay(day.value)"
			@keydown.enter.stop="onSelectDay(day.value)"
		>
			{{ day.label }}
		</div>
	</div>
</template>

<script>
/**
 * Const representing the days of the week.
 *
 * @type {Array}
 */
const DAYS_OF_WEEK = [
	{
		label: 'S',
		name: 'Sunday',
		value: 'SU',
	},
	{
		label: 'M',
		name: 'Monday',
		value: 'MO',
	},
	{
		label: 'T',
		name: 'Tuesday',
		value: 'TU',
	},
	{
		label: 'W',
		name: 'Wednesday',
		value: 'WE',
	},
	{
		label: 'T',
		name: 'Thursday',
		value: 'TH',
	},
	{
		label: 'F',
		name: 'Friday',
		value: 'FR',
	},
	{
		label: 'S',
		name: 'Saturday',
		value: 'SA',
	},
]

export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the given day to the list of selected days.
		 *
		 * @param {String} day
		 * @return {void}
		 */
		addDayToSelected(day) {
			const days = [...this.days]

			days.push(day)

			return this.$emit('days:set', days)
		},

		/**
		 * Change the default day.
		 *
		 * @return {void}
		 */
		changeDefaultDay() {
			if (this.default.length === 0) {
				return
			}

			this.$emit('days:set', [this.default.toUpperCase()])
		},

		/**
		 * Handle the day selection.
		 *
		 * @param {string} day
		 * @return {void}
		 */
		onSelectDay(day) {
			if (day === this.default.toUpperCase()) {
				return
			}

			const index = this.days.indexOf(day)

			if (index > -1) {
				return this.removeDayByIndex(index)
			}

			this.addDayToSelected(day)
		},

		/**
		 * Remove a day from the selected days.
		 *
		 * @param {Number} index
		 * @return {void}
		 */
		removeDayByIndex(index) {
			const days = [...this.days]

			days.splice(index, 1)

			return this.$emit('days:set', days)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'DaysOfWeekSelector',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The selected week days.
		 *
		 * @type {Array}
		 */
		days: {
			type: Array,
			default() {
				return []
			},
		},

		/**
		 * The default day.
		 *
		 * @type {String}
		 */
		default: {
			type: String,
			default: '',
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the default day prop for changes.
		 */
		default: {
			handler: 'changeDefaultDay',
			immediate: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			daysOfWeek: DAYS_OF_WEEK,
		}
	},
}
</script>
