<template>
	<div class="fc-event-title-wrapper" :class="wrapperStyles">
		<event-time
			:multi-day="!isAllDay && multipleDays"
			:recurring-icon="isRecurring"
		>
			{{ eventTimeDisplayString }}
		</event-time>
		<event-title :class="titleStyles" :has-requests="hasRequests">
			{{ event.title }}
		</event-title>

		<event-subtitle
			v-if="hasSecondary || hasLinkedPartnerCalendar"
			:class="subtitleStyles"
			:calendar-icon="hasLinkedPartnerCalendar"
		>
			{{ subTitleText }}
		</event-subtitle>
	</div>
</template>
<script>
import moment from 'moment'
import EventTime from './EventTime.vue'
import EventTitle from './EventTitle.vue'
import EventSubtitle from './EventSubtitle.vue'
import _clone from 'lodash/clone'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		EventSubtitle,
		EventTime,
		EventTitle,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the event's display color.
		 *
		 * @return {String}
		 */
		displayColor() {
			return this.extendedProps?.displayColor
		},

		/**
		 * Get the event's end time.
		 *
		 * @return {Moment}
		 */
		end() {
			return moment(this.event.end)
		},

		/**
		 * Get an event's calendar time string to display.
		 *
		 * @return {String}
		 */
		eventTimeDisplayString() {
			const isDST = this.start.isDST()
			const eventStartDate = this.extendedProps?.eventStartDate
			const eventStartDateDst = moment(eventStartDate).isDST()

			const startTime = _clone(this.start)
			const endTime = _clone(this.end)
			if (this.isRecurring) {
				if (eventStartDateDst === true && isDST === false) {
					startTime.subtract(1, 'hour')
					endTime.subtract(1, 'hour')
				}
				if (eventStartDateDst === false && isDST === true) {
					startTime.add(1, 'hour')
					endTime.add(1, 'hour')
				}
			}

			const eventStart = startTime.format(
				this.getEventTimeFormat(this.event.start)
			)

			const eventEnd = endTime.format(
				this.getEventTimeFormat(this.event.end)
			)

			return `${eventStart} - ${eventEnd}`
		},

		/**
		 * Get the event's extended properties.
		 *
		 * @return {Object}
		 */
		extendedProps() {
			return this.event.extendedProps
		},

		/**
		 * Determine if the event has a linked calendar.
		 *
		 * @return {Boolean}
		 */
		hasLinkedPartnerCalendar() {
			return !!this.extendedProps.linkedPartnerCalendar.name
		},

		/**
		 * Determine if the event has requests.
		 *
		 * @return {Boolean}
		 */
		hasRequests() {
			return this.extendedProps?.hasRequests
		},

		/**
		 * Determine if the event has a secondary provider.
		 *
		 * @return {Boolean}
		 */
		hasSecondary() {
			return !!this?.extendedProps?.secondary?.title
		},

		/**
		 * Determine if the event is all day.
		 *
		 * @return {Boolean}
		 */
		isAllDay() {
			return this.extendedProps?.isAllDay
		},

		/**
		 * Determine if the event is recurring.
		 *
		 * @return {Boolean}
		 */
		isRecurring() {
			return this.extendedProps?.is_recurring
		},

		/**
		 * Get the event's linked partner calendar.
		 *
		 * @return {Object}
		 */
		linkedPartnerCalendar() {
			return this.extendedProps?.linkedPartnerCalendar
		},

		/**
		 * Get the subtitle styles.
		 *
		 * @return {Array}
		 */
		subtitleStyles() {
			return [
				this.linkedPartnerCalendar?.color?.bgClass,
				this.secondary?.color?.bgClass,
			]
		},

		/**
		 * Determine if the event is a multi-day event.
		 *
		 * @return {Boolean}
		 */
		multipleDays() {
			return this.extendedProps?.multipleDays
		},

		/**
		 * Get the event's secondary provider.
		 *
		 * @return {}
		 */
		secondary() {
			return this.extendedProps?.secondary
		},

		/**
		 * Get the secondary provider color class.
		 *
		 * @return {String}
		 */
		secondaryColorClass() {
			return this.secondary?.color?.bgClass
		},

		/**
		 * Get the sub title text.
		 *
		 * @return {String}
		 */
		subTitleText() {
			if (this.hasSecondary) {
				return this.secondary.title
			}

			if (this.hasLinkedPartnerCalendar) {
				return this.linkedPartnerCalendar.name
			}

			return ''
		},

		/**
		 * Get the event's start time.
		 *
		 * @return {Moment}
		 */
		start() {
			return moment(this.event.start)
		},

		/**
		 * Get the title styles.
		 *
		 * @return {Array}
		 */
		titleStyles() {
			return [
				this.displayColor.bgClass,
				{
					'with-linked-partner-calendar': this
						.hasLinkedPartnerCalendar,
				},
			]
		},

		/**
		 * Get the wrapper styles.
		 *
		 * @return {Object}
		 */
		wrapperStyles() {
			return {
				'with-secondary': this.hasSecondary,
				'multi-day-event': this.multipleDays,
			}
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get an event's time format based on the minutes.
		 *
		 * @param {Date} datetime
		 * @return {String}
		 */
		getEventTimeFormat(datetime) {
			let format = 'ha'

			if (datetime && datetime.getMinutes() > 0) {
				format = 'h:mma'
			}

			if (this.multipleDays) {
				return `ddd ${format}`
			}

			return format
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Event',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event to be rendered.
		 *
		 * @type {Object}
		 */
		event: {
			type: Object,
			required: true,
		},
	},
}
</script>
