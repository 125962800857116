<template>
	<div class="flex flex-col">
		<div v-if="isRecurring && !isParentEvent" class="form-group">
			<label class="form-label">
				Delete recurring event:
			</label>
			<div class="form-radio">
				<input
					id="delete-one-event"
					v-model="following"
					checked
					type="radio"
					class="form-radio-input"
					:value="false"
				/>
				<label class="form-radio-label" for="delete-one-event">
					This event
				</label>
			</div>
			<div class="form-radio">
				<input
					id="delete-following-events"
					v-model="following"
					type="radio"
					:value="true"
					class="form-radio-input"
				/>
				<label class="form-radio-label" for="delete-following-events">
					This and the following events
				</label>
			</div>
		</div>
		<div
			v-if="isEditableFromCalendar"
			class="flex justify-center mb-6 font-nunito"
		>
			<span v-if="isEditableFromCalendar">
				Are you sure you wish to delete this event?
			</span>
			<span v-else>
				Are you sure you wish to unshare this event?
			</span>
		</div>
		<div class="flex justify-between">
			<button
				ref="last"
				type="button"
				class="btn btn-dark w-50 mr-2"
				data-cy="cancel-btn"
				@click.prevent="onCancel"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'times-octagon']"
					aria-hidden="true"
				/>
				<span class="btn-label">Cancel</span>
			</button>
			<button
				type="button"
				class="btn btn-raised btn-danger w-50 ml-2"
				data-cy="delete-btn"
				@click.prevent="onDeleteClick"
				@keydown.tab="onLastElementTab"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon class="btn-icon" :icon="['far', 'trash']" />
				<span v-if="isEditableFromCalendar" class="btn-label">
					Delete
				</span>
				<span v-else class="btn-label">
					Unlink
				</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the cancel event.
		 *
		 * @return {void}
		 */
		onCancel() {
			this.$emit('cancel')
		},

		/**
		 * Handle the delete click event.
		 *
		 * @return {void}
		 */
		onDeleteClick() {
			this.$emit('delete', this.following)
		},

		/**
		 * Handle the last element tab event.
		 *
		 * @param {Object} event
		 * @return {void}
		 */
		onLastElementTab(event) {
			this.$emit('last-element-tab', event)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'DeleteEventActions',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine is the event is editable from the current calendar.
		 *
		 * @type {Boolean}
		 */
		isEditableFromCalendar: {
			type: Boolean,
			required: true,
		},

		/**
		 * Determine if the event is a parent event.
		 *
		 * @type {Boolean}
		 */
		isParentEvent: {
			type: Boolean,
			default: false,
		},

		/**
		 * Determine if the event is recurrent.
		 *
		 * @type {Boolean}
		 */
		isRecurring: {
			type: Boolean,
			default: false,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			following: false,
		}
	},
}
</script>
