<template>
	<div class="form-group">
		<div class="flex justify-between">
			<label class="form-label">All providers</label>
			<label class="form-label">Group Coverage</label>
		</div>
		<div class="table-responsive">
			<table class="table">
				<thead>
					<tr>
						<th>Provider</th>
						<th>Primary</th>
						<th>Secondary</th>
					</tr>
				</thead>
				<tbody>
					<template>
						<group-coverage-row
							v-for="provider in providerGroupProviders"
							:key="provider.id"
							:covered="provider"
							:event="event"
							:group-id="groupId"
							@updated="onGroupChange"
						/>
					</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import GroupCoverageRow from './GroupCoverageRow'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		GroupCoverageRow,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current route's calendar.
		 *
		 * @return {Object}
		 */
		calendar() {
			return this.findCalendar(this.$route.params.id)
		},

		...mapGetters({
			findCalendar: 'calendars/find',
			hasOfficeManagerAccess: 'auth/hasOfficeManagerAccess',
		}),

		...mapGetters('partners', {
			partner: 'active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on group coverage change event.
		 *
		 * @param {Array} groups
		 * @return {void}
		 */
		onGroupChange({ covered_id, coverages }) {
			const primaryIndex = this.coverages.findIndex(coverage => {
				return (
					coverage.covered_id === covered_id &&
					coverage.position === 1
				)
			})

			if (primaryIndex > -1) {
				this.coverages.splice(primaryIndex, 1)
			}

			const secondaryIndex = this.coverages.findIndex(coverage => {
				return (
					coverage.covered_id === covered_id &&
					coverage.position === 2
				)
			})

			if (secondaryIndex > -1) {
				this.coverages.splice(secondaryIndex, 1)
			}

			coverages.forEach(coverage => this.coverages.push(coverage))

			this.$emit('coverage:set', this.coverages)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'GroupCoverageTable',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current event being updated.
		 *
		 * @type {Object}
		 */
		event: {
			type: Object,
			default: () => null,
		},

		/**
		 * The selected provider group.
		 *
		 * @type {Number}
		 */
		groupId: {
			type: Number,
			required: true,
		},

		/**
		 * The calendar's covered providers.
		 *
		 * @type {Array}
		 */
		providerGroupProviders: {
			type: Array,
			required: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			coverages: [],
		}
	},
}
</script>
