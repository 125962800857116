import Form from './Form'
import moment from 'moment'

/**
 * The date/time format received from the API.
 *
 * @type {String}
 */
const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

/**
 * Edit calendar event form manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class EditEventForm extends Form {
	/**
	 * Create a new EditEventForm instance.
	 */
	constructor() {
		super(EditEventForm.getFields())
	}

	/**
	 * Determine if the event duration is less than 24 hours.
	 *
	 * @param {moment} start
	 * @return {Number}
	 */
	isLessThan24Hours() {
		const start = moment(this.starts_at, API_DATE_FORMAT)
		const end = moment(this.ends_at, API_DATE_FORMAT)

		const duration = end.diff(start)

		const hours = moment.duration(duration).asHours()

		return hours <= 24 && hours > 0
	}

	/**
	 * Get the default form fields.
	 *
	 * @return {Object}
	 */
	static getFields() {
		return {
			ends_at: null,
			providers: [],
			coverages: [],
			duration: null,
			starts_at: null,
			recurrence: {
				frequency: null,
				interval: 1,
				byweekday: [],
				until: null,
			},
			calendar_id: null,
			event_type_id: null,
		}
	}
}
