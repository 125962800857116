/**
 * The application's styling breakpoints (matches scss breakpoints).
 *
 * @type {Object}
 */
const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
}

/**
 * Browser helpers.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * Determine if the current window size is extra large.
	 *
	 * @return {Boolean}
	 */
	isExtraLargeScreen() {
		return window.matchMedia(`(min-width: ${breakpoints.xl}px)`).matches
	},

	/**
	 * Determine if the current window size is an extra screen.
	 *
	 * @return {Boolean}
	 */
	isExtraSmallScreen() {
		return window.matchMedia(`(max-width: ${breakpoints.sm}px)`).matches
	},

	/**
	 * Determine if the current window size is large.
	 *
	 * @return {Boolean}
	 */
	isLargeScreen() {
		const min = breakpoints.lg
		const max = breakpoints.xl - 1

		if (window.matchMedia(`(max-width: ${max}px)`).matches) {
			return false
		}

		return window.matchMedia(`(min-width: ${min}px)`).matches
	},

	/**
	 * Determine if the current window size is medium.
	 *
	 * @return {Boolean}
	 */
	isMediumScreen() {
		const min = breakpoints.md
		const max = breakpoints.lg - 1

		if (window.matchMedia(`(max-width: ${max}px)`).matches) {
			return false
		}

		return window.matchMedia(`(min-width: ${min}px)`).matches
	},

	/**
	 * Determine if the current window size is small.
	 *
	 * @return {Boolean}
	 */
	isSmallScreen() {
		const min = breakpoints.sm
		const max = breakpoints.md - 1

		if (window.matchMedia(`(max-width: ${max}px)`).matches) {
			return false
		}

		return window.matchMedia(`(min-width: ${min}px)`).matches
	},
}
