import moment from 'moment'
import store from '@/store'
import Model from './Model'

/**
 * Event model.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class Event extends Model {
	/**
	 * Get the model's castable attribute definitions.
	 *
	 * @return {Object}
	 */
	get casts() {
		return {
			calendar_id: 'int',
			ends_at: 'datetime:YYYY-MM-DD HH:mm:ss',
			starts_at: 'datetime:YYYY-MM-DD HH:mm:ss',
		}
	}

	/**
	 * Get the fillable attributes.
	 *
	 * @return {Array}
	 */
	get fillable() {
		return [
			'id',
			'calendars',
			'duration',
			'ends_at',
			'event_type_id',
			'exceptions',
			'is_complete',
			'is_recurring',
			'recurrence',
			'requests',
			'starts_at',
			'user_id',
			'primary_calendar_id',
			'created_at',
			'updated_at',
			'calendar_id',
			'type',
			'eventables',
		]
	}

	/**
	 * Determine if the event has ended.
	 *
	 * @return {Boolean}
	 */
	ended() {
		return this.ends_at.isSameOrAfter()
	}

	/**
	 * Get the linked calendar model from the store.
	 *
	 * @return {Object}
	 */
	getLinkedCalendar() {
		return this._findLinkedCalendar(this.id)
	}

	/**
	 * Get the linked partner calendar name.
	 *
	 * @return {String}
	 */
	getLinkedPartnerCalendarName() {
		const calendar = this.getLinkedCalendar()

		if (!calendar) {
			return
		}

		return calendar.name
	}

	/**
	 * Get the primary provider model from the store.
	 *
	 * @return {Object}
	 */
	getProvider() {
		return this.getPrimary()
	}

	/**
	 * Get the provider group model from the store.
	 *
	 * @return {Object}
	 */
	getProviderGroup() {
		return this._findProviderGroup(this.id)
	}

	/**
	 * Get the primary provider model from the store,
	 *
	 * @return {Object}
	 */
	getPrimary() {
		return this._findPrimaryProvider(this.id)
	}

	/**
	 * Get the secondary provider model from the store.
	 *
	 * @return {Object}
	 */
	getSecondary() {
		return this._findSecondaryProvider(this.id)
	}

	/**
	 * Determine if the event has linked partner.
	 *
	 * @return {Boolean}
	 */
	hasLinkedPartner() {
		return this._isLinkedCalendarEvent(this.id)
	}

	/**
	 * Determine if the event has provider group.
	 *
	 * @return {Boolean}
	 */
	hasProviderGroup() {
		return this._isProviderGroupEvent(this.id)
	}

	/**
	 * Determine if the given event has cover my call requests.
	 *
	 * @return {Boolean}
	 */
	hasRequests() {
		return this.requests.length > 0
	}

	/**
	 * Determine if the event has secondary provider.
	 *
	 * @return {Boolean}
	 */
	hasSecondary() {
		return this._hasSecondaryProvider(this.id)
	}

	/**
	 * Determine id the event is currently active.
	 *
	 * @return {Boolean}
	 */
	isActive() {
		return this.started() && !this.ended()
	}

	/**
	 * Determine if the event is a multi-day all-day event.
	 *
	 * @return {Boolean}
	 */
	isAllDay() {
		return (
			moment.duration(this.ends_at.diff(this.starts_at)).as('hours') >= 24
		)
	}

	/**
	 * Determine if the event duration is less than 24 hours.
	 *
	 * @param {moment} start
	 * @return {Number}
	 */
	isLessThan24Hours() {
		const duration = this.ends_at.diff(this.starts_at)

		return moment.duration(duration).asHours() < 24
	}

	/**
	 * Determine if the event is a linked partner type.
	 *
	 * @return {Boolean}
	 */
	isLinkedCalendarType() {
		return this.isType('linked-calendar')
	}

	/**
	 * Determine if the event spans multiple days.
	 *
	 * @return {Boolean}
	 */
	isMultipleDays() {
		const start = this.starts_at.clone().local()
		const end = this.ends_at.clone().local()

		return start.format('MM/DD/YYYY') !== end.format('MM/DD/YYYY')
	}

	/**
	 * Determine if the event is a provider type.
	 *
	 * @return {Boolean}
	 */
	isProviderType() {
		return this.isType('provider')
	}

	/**
	 * Determine if the event is a provider group type.
	 *
	 * @return {Boolean}
	 */
	isProviderGroupType() {
		return this.isType('provider-group')
	}

	/**
	 * Determine if the event is of the given type.
	 *
	 * @param {String} type
	 * @return {Boolean}
	 */
	isType(type) {
		return this.type.name === type
	}

	/**
	 * Determine if the event has started.
	 *
	 * @return {Boolean}
	 */
	started() {
		return this.starts_at.isSameOrBefore()
	}

	/**
	 * Get the linked partner model from the store.
	 *
	 * @param {Number} id
	 * @return {Object}
	 */
	_findLinkedCalendar(id) {
		return store.getters['events/getLinkedCalendar'](id)
	}

	/**
	 * Get a primary provider by event id in the event providers store module.
	 *
	 * @param {Number} id
	 * @return {Object?}
	 */
	_findPrimaryProvider(id) {
		return store.getters['events/getPrimaryProvider'](id)
	}

	/**
	 * Get a secondary provider by event id in the event providers store module.
	 *
	 * @param {Number} id
	 * @return {Object?}
	 */
	_findSecondaryProvider(id) {
		return store.getters['events/getSecondaryProvider'](id)
	}

	/**
	 * Find a linked partner by id.
	 *
	 * @param {Number} id
	 * @return {Object?}
	 */
	_findPartner(id) {
		const active = store.getters['partners/active']

		const linkedPartners = active?.linked_partners || []

		return linkedPartners.find(partner => partner.id === id)
	}

	/**
	 * Get a provider group by event id in the store module.
	 *
	 * @param {Number} eventId
	 * @return {Object?}
	 */
	_findProviderGroup(eventId) {
		return store.getters['events/getProviderGroup'](eventId)
	}

	/**
	 * Determine if the selected event is a linked calendar event.
	 *
	 * @param {Number} id
	 * @return {Boolean}
	 */
	_isLinkedCalendarEvent(id) {
		return store.getters['events/isLinkedCalendarEvent'](id)
	}

	/**
	 * Determine if the selected event is a provider group event.
	 *
	 * @param {Number} id
	 * @return {Boolean}
	 */
	_isProviderGroupEvent(id) {
		return store.getters['events/isProviderGroupEvent'](id)
	}

	/**
	 * Determine if the selected event has secondary provider.
	 *
	 * @param {Number} id
	 * @return {Boolean}
	 */
	_hasSecondaryProvider(id) {
		return store.getters['events/hasSecondaryProvider'](id)
	}
}
