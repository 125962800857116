<template functional>
	<div class="fc-event-time">
		<font-awesome-icon
			v-if="props.multiDay"
			aria-hidden="true"
			data-cy="multi-day-icon"
			class="flex mr-2"
			:icon="['fad', 'eclipse']"
		/>
		<span>
			<slot />
		</span>
		<font-awesome-icon
			v-if="props.recurringIcon"
			data-cy="recurring-icon"
			aria-hidden="true"
			class="flex"
			:icon="['fas', 'repeat']"
		/>
	</div>
</template>

<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EventTime',
}
</script>
