import Form from './Form'
import moment from 'moment'

/**
 * The format used to generate date/time strings.
 *
 * @type {String}
 */
const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'

/**
 * The date/time format received from the API.
 *
 * @type {String}
 */
const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

/**
 * Create calendar event form manager.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class CreateEventForm extends Form {
	/**
	 * Create a new CreateEventForm instance.
	 */
	constructor() {
		super(CreateEventForm.getFields())
	}

	/**
	 * Determine if the event duration is less than 24 hours.
	 *
	 * @param {moment} start
	 * @return {Number}
	 */
	isLessThan24Hours() {
		const start = moment(this.starts_at, API_DATE_FORMAT)
		const end = moment(this.ends_at, API_DATE_FORMAT)

		const duration = end.diff(start)

		const hours = moment.duration(duration).asHours()

		return hours <= 24 && hours > 0
	}

	/**
	 * Get the default form fields.
	 *
	 * @return {Object}
	 */
	static getFields() {
		const maxEventDate = moment()
			.startOf('day')
			.add(366, 'day')
			.format(API_DATE_FORMAT)

		return {
			calendars: [],
			coverages: [],
			duration: null,
			ends_at: moment().format(DATETIME_FORMAT),
			providers: [],
			starts_at: moment().format(DATETIME_FORMAT),
			recurrence: {
				frequency: null,
				interval: 1,
				byweekday: [],
				until: maxEventDate,
			},
			calendar_id: null,
			event_type_id: null,
			use_time_blocks: false,
			use_default_times: false,
		}
	}
}
