<template>
	<div class="table-responsive">
		<table class="table table-auto">
			<thead>
				<tr>
					<th>Sender</th>
					<th>Recipient</th>
					<th>Time</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody v-if="(requests || []).length">
				<tr v-for="request in requests" :key="request.id">
					<td>{{ request.sender.full_name }}</td>
					<td>{{ request.recipient.full_name }}</td>
					<td class="whitespace-pre-wrap break-words">
						<div>
							{{
								request.starts_at
									| toDateTimeFormat('MM/DD/YY hh:mm A')
							}}
						</div>
						<div>
							{{
								request.ends_at
									| toDateTimeFormat('MM/DD/YY hh:mm A')
							}}
						</div>
					</td>
					<td>
						<strong>
							{{ request.status.name | ucFirst }}
						</strong>
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td class="text-center" colspan="4">
						No Cover My Call requests have been sent for this event.
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CoverMyCallRequests',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event's cover my call requests.
		 *
		 * @type {Array}
		 */
		requests: {
			type: Array,
			required: true,
		},
	},
}
</script>
