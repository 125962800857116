/**
 * Form errors manager.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class FormErrors {
	/**
	 * Make a new FormErrors instance.
	 *
	 * @param {Array|Object} form
	 */
	constructor(form) {
		this.fields = Array.isArray(form) ? form : Object.keys(form)

		this.fields.forEach(field => (this[field] = []))
	}

	/**
	 * Determine if there is any errors.
	 *
	 * @return {Boolean}
	 */
	any() {
		return this.fields.some(field => this.has(field))
	}

	/**
	 * Clear the errors from an specific field.
	 *
	 * @param {?String} field
	 * @return {FormErrors}
	 */
	clear(field = null) {
		if (!field) {
			return this.fill({})
		}

		if (this.has(field)) {
			this[field] = []
		}

		return this
	}

	/**
	 * Set the given form errors.
	 *
	 * @param {Object} errors
	 * @return {FormErrors}
	 */
	fill(errors) {
		this.fields.forEach(field => {
			this.set(field, errors[field])
		})

		return this
	}

	/**
	 * Get the first error message for the given field.
	 *
	 * @param {String} field
	 * @return {String}
	 */
	first(field) {
		if (this.has(field)) {
			return this[field][0]
		}

		return ''
	}

	/**
	 * Get the given field's form errors.
	 *
	 * @param {String} field
	 * @return {Array}
	 */
	get(field) {
		if (this.has(field)) {
			return this[field]
		}

		return []
	}

	/**
	 * Determine if the given field has errors.
	 *
	 * @param {String} field
	 * @return {Boolean}
	 */
	has(field) {
		if (this.fields.indexOf(field) > -1) {
			return this[field].length > 0
		}

		return false
	}

	/**
	 * Set the given form field's errors.
	 *
	 * @param {String} field
	 * @param {Array} errors
	 * @return {FormErrors}
	 */
	set(field, errors = []) {
		if (this.fields.indexOf(field) > -1) {
			this[field] = Array.isArray(errors) ? errors : []
		}

		return this
	}
}
