<template>
	<div>
		<div>
			<div class="form-group">
				<label class="form-label">
					Recurrence:
				</label>
				<div class="form-radio">
					<input
						id="single-event"
						v-model="wantRecurrence"
						checked
						type="radio"
						:value="false"
						class="form-radio-input"
						@change.prevent="onWantRecurrenceChange"
					/>

					<label class="form-radio-label" for="single-event">
						Single Event
					</label>
				</div>

				<div class="form-radio">
					<input
						id="recurring-event"
						v-model="wantRecurrence"
						type="radio"
						:value="true"
						class="form-radio-input"
						@change.prevent="onWantRecurrenceChange"
					/>
					<label class="form-radio-label" for="recurring-event">
						Recurring Event
					</label>
				</div>
			</div>
			<div v-if="wantRecurrence">
				<div class="form-group">
					<label for="repeat" class="form-label">
						Frequency:
					</label>
					<div class="relative">
						<select
							id="repeat"
							v-model="frequency"
							required
							class="form-input"
							placeholder="Frequency"
							data-cy="recurrence-frequency"
						>
							<option :value="null" disabled>
								Select an option
							</option>

							<option value="DAILY">
								Daily
							</option>

							<option value="WEEKLY">
								Weekly
							</option>
						</select>

						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							></font-awesome-icon>
						</div>
					</div>
				</div>
				<div v-if="isWeekly" class="form-group">
					<label for="calendars" class="form-label">
						Repeat On:
					</label>

					<days-of-week-selector
						:default="dayName"
						:days="byweekday"
						@days:set="onDaysChange"
					/>
				</div>
				<div class="form-group">
					<label for="until" class="form-label">
						Ends On
					</label>
					<date-picker
						id="until"
						v-model="until"
						name="until"
						:min-date="form.starts_at"
						mode="date"
						color="orange"
						is-required
					>
						<template v-slot="{ inputValue, inputEvents }">
							<input
								class="form-input"
								:value="inputValue"
								:disabled="disabled"
								placeholder="Ends On"
								v-on="inputEvents"
							/>
						</template>
					</date-picker>
					<span v-if="form.errors.has('duration')" class="form-error">
						{{ form.errors.first('duration') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import DaysOfWeekSelector from '@/components/DaysOfWeekSelector'

/**
 * Const representing the timestamp format the API uses.
 *
 * @type {String}
 */
const API_FORMAT = 'YYYY-MM-DD HH:mm:ss'

/**
 * Const representing the datetime-local format.
 *
 * @type {String}
 */
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		DaysOfWeekSelector,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the day name that is selected.
		 *
		 * @return {String}
		 */
		dayName() {
			return moment(this.form.starts_at, DATE_TIME_FORMAT).format('dd')
		},

		/**
		 * The event days selected array.
		 *
		 * @type {Object}
		 */
		byweekday: {
			/**
			 * Get the days selected array.
			 *
			 * @return {Array}
			 */
			get() {
				if (!this.form?.recurrence) {
					return []
				}

				return this.form.recurrence.byweekday
			},

			/**
			 * Set the days selected array.
			 *
			 * @param {Array} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('byweekday:change', value)
			},
		},

		/**
		 * The event frequency object.
		 *
		 * @type {Object}
		 */
		frequency: {
			/**
			 * Get the frequency object.
			 *
			 * @return {String}
			 */
			get() {
				if (!this.form?.recurrence) {
					return null
				}

				return this.form.recurrence.frequency
			},

			/**
			 * Set the frequency object.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('frequency:change', value)

				if (value === 'WEEKLY' && !this.form?.recurrence?.until) {
					this.form.recurrence.until = this.setUntilValue()
				}
			},
		},

		/**
		 * Determine if the event has daily recurrence.
		 *
		 * @return {Boolean}
		 */
		isDaily() {
			return !!(this.frequency === 'DAILY')
		},

		/**
		 * Determine if the event has weekly recurrence.
		 *
		 * @return {Boolean}
		 */
		isWeekly() {
			return !!(this.frequency === 'WEEKLY')
		},

		/**
		 * The event recurrence until day.
		 *
		 * @type {Object}
		 */
		until: {
			/**
			 * Get the recurrence until day.
			 *
			 * @return {Array}
			 */
			get() {
				if (!this.form?.recurrence) {
					return null
				}

				return moment(this.form.recurrence.until, API_FORMAT).format(
					DATE_TIME_FORMAT
				)
			},

			/**
			 * Set the days selected array.
			 *
			 * @param {Array} value
			 * @return {void}
			 */
			set(value) {
				this.$emit(
					'until:change',
					moment(value).format(DATE_TIME_FORMAT)
				)
			},
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on days change event.
		 *
		 * @param {Array} days
		 * @return {void}
		 */
		onDaysChange(days) {
			this.byweekday = days
		},

		/**
		 * Handle the isRecurring property change.
		 *
		 * @return {void}
		 */
		onRecurringChange() {
			this.wantRecurrence = this.isRecurring
		},

		/**
		 * Handle the on want end date change event.
		 *
		 * @return {void}
		 */
		onCustomEndDateChange() {
			if (this.isCustomEndDate) {
				return
			}

			const MAX_END_TIME = moment()
				.add(1, 'year')
				.format(API_FORMAT)

			this.$emit('until:change', MAX_END_TIME)
		},

		/**
		 * Handle the on want recurrence change event.
		 *
		 * @return {void}
		 */
		onWantRecurrenceChange() {
			this.$emit('recurrence:change', this.wantRecurrence)
		},

		/**
		 * Set the until value to be the next year.
		 *
		 * @return {String}
		 */
		setUntilValue() {
			return moment(this.form.starts_at, API_FORMAT)
				.utc()
				.endOf('day')
				.add(365, 'day')
				.format(API_FORMAT)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'RecurringEventActions',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the component should be disabled.
		 *
		 * @type {Boolean}
		 */
		disabled: {
			type: Boolean,
			default: false,
		},

		/**
		 * The form fields to be handled.
		 *
		 * @type {Object}
		 */
		form: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},

		/**
		 * Determine if the event has recurrence.
		 *
		 * @type {Boolean}
		 */
		isRecurring: {
			type: Boolean,
			default: false,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the event object for changes.
		 *
		 * @return {void}
		 */
		isRecurring: {
			handler: 'onRecurringChange',
			immediate: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			wantRecurrence: false,
		}
	},
}
</script>
