import { debounce } from 'lodash'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin from '@fullcalendar/interaction'

/**
 * Constant representing the day grid month view.
 */
const DAY_GRID_MONTH = 'dayGridMonth'

/**
 * Constant representing the list week view.
 */
const LIST_WEEK = 'listWeek'

/**
 * Constant representing the hour format.
 */
const HOUR_FORMAT = 'numeric'

/**
 * Constant representing the minute format.
 */
const MINUTE_FORMAT = '2-digit'

/**
 * Constant representing the scheduler licence key.
 */
const SCHEDULER_LICENSE_KEY = 'CC-Attribution-NonCommercial-NoDerivatives'

/**
 * The full calendar configuration class.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class FullCalendarConfig {
	/**
	 * Create a new FullCalendarConfig instance.
	 */
	constructor() {
		const config = this.getDefaultConfig()

		Object.keys(config).forEach(key => {
			this.set(key, config[key])
		})
	}

	/**
	 * Set the given key a class property.
	 *
	 * @param {String} key
	 * @param {Object|Function} handler
	 * @return {FullCalendarConfig}
	 */
	set(key, handler) {
		this[key] = handler

		return this
	}

	/**
	 * Set the calendar's business hours.
	 *
	 * @param {Array} businessHours
	 * @return {FullCalendarConfig}
	 */
	setBusinessHours(businessHours) {
		return this.set('businessHours', businessHours)
	}

	/**
	 * Set the date click function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setDateClick(handler) {
		return this.set('dateClick', handler)
	}

	/**
	 * Set the day call class names click function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setDayCellClassNames(handler) {
		return this.set('dayCellClassNames', handler)
	}

	/**
	 * Set the drop function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setDrop(handler) {
		return this.set('drop', debounce(handler, 250, { leading: false }))
	}

	/**
	 * Set the calendar's events.
	 *
	 * @param {Array} events
	 * @return {FullCalendarConfig}
	 */
	setEvents(events) {
		return this.set('events', events)
	}

	/**
	 * Set the event click function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventClick(handler) {
		return this.set('eventClick', handler)
	}

	/**
	 * Set the event content function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventContent(handler) {
		return this.set('eventContent', handler)
	}

	/**
	 * Set the event drop function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventDrop(handler) {
		return this.set('eventDrop', handler)
	}

	/**
	 * Set the event mouse enter function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventMouseEnter(handler) {
		return this.set('eventMouseEnter', handler)
	}

	/**
	 * Set the event mouse leave function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventMouseLeave(handler) {
		return this.set('eventMouseLeave', handler)
	}

	/**
	 * Set the event resize function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setEventResize(handler) {
		return this.set('eventResize', handler)
	}

	/**
	 * Set the full calendar's select function.
	 *
	 * @param {Number} rows
	 * @return {FullCalendarConfig}
	 */
	setDayMaxEventRows(rows) {
		const views = {
			dayGridMonth: {
				dayMaxEventRows: rows,
			},
		}

		return this.set('views', views)
	}

	/**
	 * Set the navbar link day click function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setNavLinkDayClick(handler) {
		return this.set('navLinkDayClick', handler)
	}

	/**
	 * Set the full calendar's select function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setSelect(handler) {
		return this.set('select', handler)
	}

	/**
	 * Set the window resize function.
	 *
	 * @param {Function} handler
	 * @return {FullCalendarConfig}
	 */
	setWindowResize(handler) {
		return this.set('windowResize', handler)
	}

	/**
	 * Get the default full calendar configuration.
	 *
	 * @return {Object}
	 */
	getDefaultConfig() {
		return {
			dayMaxEventRows: true,
			displayEventEnd: true,
			displayEventTime: true,
			droppable: true,
			editable: true,
			events: [],
			eventTimeFormat: {
				hour: HOUR_FORMAT,
				hour12: true,
				minute: MINUTE_FORMAT,
				omitZeroMinute: true,
			},
			expandRows: true,
			initialView: window.innerWidth > 576 ? DAY_GRID_MONTH : LIST_WEEK,
			headerToolbar: {
				center: null,
				left: null,
				right: null,
			},
			height: 'auto',
			navLinks: true,
			nowIndicator: true,
			plugins: [
				adaptivePlugin,
				interactionPlugin,
				dayGridPlugin,
				timeGridPlugin,
				listPlugin,
				rrulePlugin,
			],
			schedulerLicenseKey: SCHEDULER_LICENSE_KEY,
			selectable: true,
			selectMirror: false,
		}
	}
}
