<template>
	<panel :show="show" large @close="close">
		<template #title>
			Create Event
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<event-type-selector v-model="eventType" />

				<div v-if="isProviderType" class="form-group">
					<label for="provider_id" class="form-label">
						Provider
					</label>

					<multiselect
						id="provider_id"
						ref="multiselect"
						v-model="primaryProvider"
						required
						track-by="id"
						label="label"
						placeholder="Select a provider"
						:options="allProviders"
						:multiple="false"
						:select-label="''"
						:selected-label="''"
					>
						<template slot="noResult">
							No providers found
						</template>
					</multiselect>

					<span
						v-if="form.errors.has('providers')"
						class="form-error"
					>
						{{ form.errors.first('providers') }}
					</span>
				</div>

				<div v-if="showSecondaryProvider" class="form-group">
					<label for="secondary_provider_id" class="form-label">
						Secondary Contact (Optional)
					</label>

					<multiselect
						id="secondary_provider_id"
						ref="multiselect"
						v-model="secondaryProvider"
						required
						track-by="id"
						label="label"
						placeholder="Select a provider"
						:options="internalProviders"
						:multiple="false"
						:select-label="''"
						:selected-label="''"
					>
						<template slot="noResult">
							No providers found
						</template>
					</multiselect>
				</div>

				<div v-if="isLinkedCalendarType" class="form-group">
					<label for="linked_partner_id" class="form-label">
						Affiliate Practice
					</label>

					<div class="relative">
						<select
							id="linked_partner_id"
							v-model="linkedPartner"
							required
							name="linked_partner_id"
							placeholder="Select an affiliate practice"
							class="form-input"
							:class="[
								linkedPartner
									? 'text-gray-700'
									: 'text-gray-500',
							]"
						>
							<option :value="null" disabled
								>Select an Affiliate Practice</option
							>
							<option
								v-for="partner in linkedPartners"
								:key="partner.id"
								:value="partner.id"
								>{{ partner.name }}</option
							>
						</select>

						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							></font-awesome-icon>
						</div>
					</div>
				</div>

				<div v-if="linkedPartner" class="form-group">
					<label for="linked_partner_calendar_id" class="form-label">
						Calendar (* Default Calendar)
					</label>
					<div class="relative mb-2">
						<select
							id="linked_partner_calendar_id"
							v-model="linkedCalendarId"
							required
							name="linked_partner_calendar_id"
							placeholder="Select a Calendar"
							class="form-input"
							:class="[
								linkedCalendarId
									? 'text-gray-700'
									: 'text-gray-500',
							]"
						>
							<option :value="null" disabled>
								Select a Calendar
							</option>

							<option
								v-for="linkedCalendar in linkedPartnerCalendars"
								:key="`calendar-${linkedCalendar.id}`"
								:value="linkedCalendar.id"
							>
								{{ linkedCalendar.name }}
								{{ linkedCalendar.is_default ? '*' : '' }}
							</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							/>
						</div>
					</div>
				</div>

				<div v-if="isProviderGroupType" class="form-group">
					<label for="provider_group_id" class="form-label">
						Provider Group
					</label>
					<div class="relative">
						<select
							id="provider_group_id"
							v-model="providerGroupId"
							required
							name="provider_group_id"
							class="form-input"
							:class="[
								providerGroupId
									? 'text-gray-700'
									: 'text-gray-500',
							]"
						>
							<option :value="null" disabled>
								Select a provider group
							</option>

							<option
								v-for="groups in providerGroups"
								:key="groups.id"
								:value="groups.id"
							>
								{{ groups.name }}
							</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							/>
						</div>
					</div>
				</div>

				<group-coverage-table
					v-if="isProviderGroupType && providerGroupId"
					:group-id="providerGroupId"
					:provider-group-providers="providerGroupProviders"
					@coverage:set="onGroupChange"
				/>

				<div v-if="hasTypeSelected" class="form-group">
					<label for="start" class="form-label">
						Start Date
						<span v-if="inDifferentTimezone && partnerStartTime">
							({{ partnerStartTime }}
							{{
								timezones[partner.timezone] || partner.timezone
							}})
						</span>
					</label>
					<date-picker
						id="start"
						v-model="form.starts_at"
						name="start"
						:min-date="today"
						mode="dateTime"
						color="orange"
						is-required
					>
						<template v-slot="{ inputValue, inputEvents }">
							<input
								class="form-input"
								:value="inputValue"
								placeholder="Start Date"
								v-on="inputEvents"
							/>
						</template>
					</date-picker>
					<span
						v-if="form.errors.has('starts_at')"
						class="form-error"
					>
						{{ form.errors.first('starts_at') }}
					</span>
				</div>

				<div v-if="hasTypeSelected" class="form-group">
					<label for="end" class="form-label">
						End Date
						<span v-if="inDifferentTimezone && partnerEndTime">
							({{ partnerEndTime }}
							{{
								timezones[partner.timezone] || partner.timezone
							}})
						</span>
					</label>
					<date-picker
						id="end"
						v-model="form.ends_at"
						name="end"
						:min-date="form.starts_at"
						mode="dateTime"
						color="orange"
						is-required
					>
						<template v-slot="{ inputValue, inputEvents }">
							<input
								class="form-input"
								:value="inputValue"
								placeholder="End Date"
								v-on="inputEvents"
							/>
						</template>
					</date-picker>
					<span v-if="form.errors.has('duration')" class="form-error">
						{{ form.errors.first('duration') }}
					</span>
				</div>

				<recurring-event-actions
					v-if="isLessThan24Hours"
					:form="form"
					:end="form.ends_at"
					:start="form.starts_at"
					@byweekday:change="onByWeekdayChange"
					@frequency:change="onFrequencyChange"
					@until:change="onUntilChange"
					@recurrence:change="onRecurringChange"
				/>

				<div
					v-if="hasShareableCalendars"
					data-cy="shareable-calendars-section"
					class="form-group"
				>
					<label class="form-label">
						Share to Calendar(s):
					</label>
					<div class="flex flex-wrap">
						<div
							v-for="shareableCalendar in shareableCalendars"
							:key="shareableCalendar.id"
							class="flex-auto w-2/4"
						>
							<div class="form-check">
								<input
									:id="
										`shareable-calendar-${shareableCalendar.id}`
									"
									v-model="calendars"
									type="checkbox"
									:value="shareableCalendar.id"
									class="form-check-input"
									name="patient-available-checkbox"
								/>
								<label
									:for="
										`shareable-calendar-${shareableCalendar.id}`
									"
									data-cy="shareable-calendar"
									class="form-check-label"
								>
									{{ shareableCalendar.name }}
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple"></div>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple"></div>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'save']"
							aria-hidden="true"
						/>
						<span class="btn-label">Save</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import moment from 'moment'
import { groupBy, sortBy } from 'lodash'
import Multiselect from 'vue-multiselect'
import timezones from '@/config/timezones'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions, mapGetters } from 'vuex'
import Panel from '@/components/Panels/Panel.vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import CreateEventForm from 'App/Forms/CreateEventForm'
import EventTypeSelector from './EventTypeSelector.vue'
import RecurringEventActions from './RecurringEventActions.vue'
import GroupCoverageTable from '@/components/GroupCoverageTable'

/**
 * Const representing the timestamp format the API uses.
 *
 * @type {String}
 */
const API_FORMAT = 'YYYY-MM-DD HH:mm:ss'

/**
 * Const representing the datetime-local format.
 *
 * @type {String}
 */
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		EventTypeSelector,
		GroupCoverageTable,
		Multiselect,
		Panel,
		RecurringEventActions,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all internal/external providers.
		 *
		 * @return {Array}
		 */
		allProviders() {
			let providers = this.getAllProviders || []

			providers = sortBy(providers, ['display_name'])

			providers = providers.map(provider => {
				const label = this.getProviderFullName(provider)

				return {
					id: provider.id,
					label,
					type: provider.type,
				}
			})

			const { external, internal } = groupBy(providers, 'type')

			return [...(internal || []), ...(external || [])]
		},

		/**
		 * Get the current route's calendar.
		 *
		 * @return {Object}
		 */
		calendar() {
			return this.findCalendar(this.$route.params.id)
		},

		/**
		 * Get the day name that is selected.
		 *
		 * @return {String}
		 */
		dayName() {
			return moment(this.startDate, 'MM-DD-YYYY').format('dd')
		},

		/**
		 * Get the selected event type name.
		 *
		 * @return {String}
		 */
		getEventTypeName() {
			return this.eventType?.name || ''
		},

		/**
		 * Check that shareable calendars exist.
		 *
		 * @return {Boolean}
		 */
		hasShareableCalendars() {
			return this.shareableCalendars.length > 0 && this.hasTypeSelected
		},

		/**
		 * Determine if the an event type has been selected.
		 *
		 * @return {Boolean}
		 */
		hasTypeSelected() {
			return !!this.getEventTypeName
		},

		/**
		 * Determine if the user is in a different timezone than the partner's timezone.
		 *
		 * @return {Boolean}
		 */
		inDifferentTimezone() {
			const now = moment()
			const partner = moment().tz(this.partner.timezone)

			return now.utcOffset() !== partner.utcOffset()
		},

		/**
		 * Get the partner's internal providers sorted by last name.
		 *
		 * @return {Array}
		 */
		internalProviders() {
			if (!this.calendar) {
				return []
			}

			return this.allProviders.filter(provider => {
				return provider.type === 'internal'
			})
		},

		/**
		 * Determine if the selected provider is internal.
		 *
		 * @return {Boolean}
		 */
		isInternalProvider() {
			if (!this.primaryProvider) {
				return false
			}

			const provider = this.allProviders.find(
				provider => provider.id === this.primaryProvider.id
			)

			return provider?.type === 'internal'
		},

		/**
		 * Determine is the type selected is linked calendar.
		 *
		 * @return {Boolean}
		 */
		isLinkedCalendarType() {
			return this.getEventTypeName === 'linked-calendar'
		},

		/**
		 * Determine is the type selected is provider group.
		 *
		 * @return {Boolean}
		 */
		isProviderGroupType() {
			return this.getEventTypeName === 'provider-group'
		},

		/**
		 * Determine is the type selected is provider.
		 *
		 * @return {Boolean}
		 */
		isProviderType() {
			return this.getEventTypeName === 'provider'
		},

		/**
		 * Get the linked partners.
		 *
		 * @return {Array}
		 */
		linkedPartners() {
			if (!this.partner) {
				return []
			}

			return sortBy(this.partner.linked_partners, 'name')
		},

		/**
		 * Get the linked partner's calendars.
		 *
		 * @return {Array}
		 */
		linkedPartnerCalendars() {
			if (!this.linkedPartner) {
				return []
			}

			const linkedPartner = this.linkedPartners.find(
				linkedPartner => linkedPartner.id === this.linkedPartner
			)

			return sortBy(linkedPartner.calendars, 'name')
		},

		/**
		 * Get the form's end time in the partner's timezone.
		 *
		 * @return {String}
		 */
		partnerEndTime() {
			if (!this.form.ends_at) {
				return ''
			}

			return moment(this.form.ends_at, DATE_TIME_FORMAT)
				.tz(this.partner.timezone)
				.format('hh:mm A')
		},

		/**
		 * Get the form's start time in the partner's timezone.
		 *
		 * @return {String}
		 */
		partnerStartTime() {
			if (!this.form.starts_at) {
				return ''
			}

			return moment(this.form.starts_at, DATE_TIME_FORMAT)
				.tz(this.partner.timezone)
				.format('hh:mm A')
		},

		/**
		 * Get the selected providers for a specific provider group.
		 *
		 * @return {Array}
		 */
		providerGroupProviders() {
			if (!this.calendar || !this.providerGroupId) {
				return []
			}

			const providerGroup = this.findProviderGroup(this.providerGroupId)

			if (!providerGroup) {
				return []
			}

			return providerGroup?.providers || []
		},

		/**
		 * Get the provider groups.
		 *
		 * @return {Array}
		 */
		providerGroups() {
			if (!this.calendar) {
				return []
			}

			return sortBy(this.getProviderGroups(this.partner.id), 'name')
		},

		/**
		 * Get the shareable calendars.
		 *
		 * @return {Array}
		 */
		shareableCalendars() {
			if (!this.calendar) {
				return []
			}

			const calendars = this.getCalendars(this.partner.id)

			if (!Array.isArray(calendars)) {
				return []
			}

			return calendars.filter(calendar => {
				return calendar.id !== this.calendar?.id
			})
		},

		/**
		 * Determine if the secondary provider dropdown should be shown.
		 *
		 * @return {Boolean}
		 */
		showSecondaryProvider() {
			return this.isProviderType && this.isInternalProvider
		},

		/**
		 * Get the current calendars timeblocks.
		 *
		 * @return {Array}
		 */
		timeBlocks() {
			return this.getTimeBlocks(this.$route.params.id)
		},

		...mapGetters({
			findCalendar: 'calendars/find',
			findProviderGroup: 'partners/findProviderGroup',
			eventTypes: 'eventTypes',
			getCalendars: 'calendars/getByPartner',
			getAllProviders: 'providers/all',
			getTimeBlocks: 'timeBlocks/getByCalendarId',
		}),

		...mapGetters('partners', {
			getLinkedPartners: 'getLinkedPartners',
			partner: 'active',
			getProviderGroups: 'getProviderGroups',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fill the form depending on the selected type.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		fillForm(data) {
			const start = this.getStartTime()

			data.starts_at = start.format(API_FORMAT)

			data.duration = this.getDuration(start)

			data.event_type_id = this.eventType.id

			data.calendar_id = this.calendar.id

			data.calendars = this.calendars

			if (this.isProviderType) {
				data.providers = this.getSelectedProviderArray()
			}

			if (this.isProviderGroupType && this.providerGroupId) {
				data.provider_groups = [this.providerGroupId]
			}

			if (this.isLinkedCalendarType && this.linkedCalendarId) {
				data.linked_calendars = [this.linkedCalendarId]
			}

			data = this.removeRecurrence(data)

			return data
		},

		/**
		 * Get the event duration.
		 *
		 * @return {Number}
		 */
		getDuration() {
			const end = moment(this.form.ends_at, DATE_TIME_FORMAT).utc()

			const duration = end.diff(this.form.starts_at)

			return moment.duration(duration).asMinutes()
		},

		/**
		 * Get the provider's full name.
		 *
		 * @param {Object} provider
		 * @return {String}
		 */
		getProviderFullName(provider) {
			if (!provider) {
				return ''
			}

			return provider.type === 'external'
				? `(Ext) ${provider.full_name}`
				: provider.full_name
		},

		/**
		 * Get the selected provider's ids as an array.
		 *
		 * @return {Array}
		 */
		getSelectedProviderArray() {
			const providers = []

			if (this.primaryProvider) {
				providers.push(this.primaryProvider.id)
			}

			if (this.secondaryProvider) {
				providers.push(this.secondaryProvider.id)
			}

			return providers
		},

		/**
		 * Get the event start time.
		 *
		 * @return {moment.Moment}
		 */
		getStartTime() {
			return moment(this.form.starts_at, DATE_TIME_FORMAT).utc()
		},

		/**
		 * Handle the recurrence by week day change event.
		 *
		 * @param {Array} days
		 * @return {void}
		 */
		onByWeekdayChange(days) {
			this.form.recurrence.byweekday = days
		},

		/**
		 * Handle the date change.
		 *
		 * @return {void}
		 */
		onDateChange() {
			this.isLessThan24Hours = this.form.isLessThan24Hours()
		},

		/**
		 * Handle the on days change event.
		 *
		 * @param {Array} days
		 * @return {void}
		 */
		onDaysChange(days) {
			this.daysSelected = days
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				if (!this.isLessThan24Hours) {
					this.form.recurrence = null
				}

				const response = await this.form.submit()

				this.$alert.response(response)
			} catch (e) {
				return this.$alert.response(e)
			}

			this.resetForm()

			this.close()
		},

		/**
		 * Handle the recurrence frequency change event.
		 *
		 * @param {String} frequency
		 * @return {void}
		 */
		onFrequencyChange(frequency) {
			this.form.recurrence.frequency = frequency
		},

		/**
		 * Handle the on group coverage change event.
		 *
		 * @param {Array} coverages
		 * @return {void}
		 */
		onGroupChange(coverages) {
			this.form.set('coverages', coverages)
		},

		/**
		 * Handle the recurrence change event.
		 *
		 * @param {Boolean} recurring
		 * @return {void}
		 */
		onRecurringChange(recurring) {
			this.recurring = recurring
		},

		/**
		 * Handle the recurrence until day event.
		 *
		 * @param {String} until
		 * @return {void}
		 */
		onUntilChange(until) {
			this.form.recurrence.until = moment(until, DATE_TIME_FORMAT).format(
				API_FORMAT
			)
		},

		/**
		 * Determine if the recurrence should be removed.
		 *
		 * @return {void}
		 */
		removeRecurrence(data) {
			if (this.recurring && this.isLessThan24Hours) {
				return data
			}

			data.recurrence = []

			return data
		},

		/**
		 * Reset all the form fields.
		 *
		 * @return {void}
		 */
		resetForm() {
			this.calendars = []
			this.eventType = null
			this.linkedPartner = null
			this.primaryProvider = null
			this.providerGroupId = null
			this.secondaryProvider = null

			this.form.reset()
		},

		/**
		 * Reset the form unslected fields based on type when the form is submitted.
		 *
		 * @param {Object} data
		 * @return {Object}
		 */
		resetUnselectedFormType(data) {
			if (this.isProviderType) {
				delete data.linked_calendars
				delete data.provider_groups
			}

			if (this.isProviderGroupType) {
				delete data.linked_calendars
				delete data.providers
			}

			if (this.isLinkedCalendarType) {
				delete data.provider_groups
				delete data.providers
			}

			return data
		},

		/**
		 *	Send the create event request.
		 *
		 * @param {?String} url
		 * @param {?String} requesType
		 * @param {Object} data
		 * @return {void}
		 */
		submitHandler(url, requesType, data) {
			return this.create({
				calendarId: this.calendar.id,
				event: data,
			})
		},

		...mapActions('events', ['create']),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CreatePanel',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the eventType property for changes.
		 */
		eventType() {
			this.linkedPartner = null
		},

		/**
		 * Watch the form.starts_at property for changes.
		 */
		'form.starts_at': {
			handler: 'onDateChange',
		},

		/**
		 * Watch the form.ends_at property for changes.
		 */
		'form.ends_at': {
			handler: 'onDateChange',
		},

		/**
		 * Watch the show property for changes.
		 *
		 * @return {void}
		 */
		show() {
			this.resetForm()
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.form.setSubmitHandler(this.submitHandler).before(form => {
			return this.fillForm(this.resetUnselectedFormType(form))
		})
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			calendars: [],
			eventType: null,
			form: new CreateEventForm(),
			isLessThan24Hours: false,
			linkedCalendarId: null,
			linkedPartner: null,
			primaryProvider: null,
			providerGroupId: null,
			secondaryProvider: null,
			timezones,
			today: moment().format(DATE_TIME_FORMAT),
			recurring: false,
		}
	},
}
</script>
