<template>
	<tab-bar>
		<tab-bar-item
			name="edit"
			:icon="['far', 'edit']"
			:current="active"
			:focused="focused"
			@blur.native="blur"
			@focus.native="focus('edit')"
			@click.native="onClick('edit')"
			@keydown.left.native="focusPrevious"
			@keydown.right.native="focusNext"
		>
			Edit
		</tab-bar-item>
		<tab-bar-item
			v-if="hasRequests"
			name="cmc"
			:icon="['far', 'people-arrows']"
			:current="active"
			:focused="focused"
			@blur.native="blur"
			@focus.native="focus('cmc')"
			@click.native="onClick('cmc')"
			@keydown.left.native="focusPrevious"
			@keydown.right.native="focusNext"
		>
			Coverage Requests
		</tab-bar-item>
		<tab-bar-item
			v-if="isRecurring"
			name="exception"
			:icon="['far', 'pencil-ruler']"
			:current="active"
			:focused="focused"
			@blur.native="blur"
			@focus.native="focus('exception')"
			@click.native="onClick('exception')"
			@keydown.left.native="focusPrevious"
			@keydown.right.native="focusNext"
		>
			Event Exceptions
		</tab-bar-item>
	</tab-bar>
</template>
<script>
import TabBar from '@/components/TabBar.vue'
import TabBarItem from '@/components/TabBarItem.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		TabBar,
		TabBarItem,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the tabs:activate event to the parent component.
		 *
		 * @param {String} name
		 * @return {VueComponent}
		 */
		activate(name) {
			this.$emit('activate', name)

			return this
		},

		/**
		 * Reset the focused tab when the focus leaves a tab item.
		 *
		 * @return {VueComponent}
		 */
		blur() {
			return this.focus('')
		},

		/**
		 * Set the current focused tab item.
		 *
		 * @param {String} name
		 * @return {VueComponent}
		 */
		focus(name) {
			this.focused = name

			return this
		},

		/**
		 * Set the focus to the next (or previous) tab item.
		 *
		 * @return {VueComponent}
		 */
		focusNext() {
			if (this.focused === 'edit') {
				return this.focus('cmc')
			}

			return this.focus('edit')
		},

		/**
		 * Set the focus to the next (or previous) tab item.
		 *
		 * @return {VueComponent}
		 */
		focusPrevious() {
			if (this.focused === 'edit') {
				return this.focus('cmc')
			}

			return this.focus('edit')
		},

		/**
		 * Set the active & focused tab to the given tab name.
		 *
		 * @param {String} name
		 * @return {Promise}
		 */
		onClick(name) {
			return this.focus(name).activate(name)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EventTabs',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The currently active/displayed tab on the page.
		 *
		 * @type {String}
		 */
		active: {
			type: String,
			required: true,
		},

		/**
		 * Determine if the event is recurring.
		 *
		 * @type {Boolean}
		 */
		isRecurring: {
			type: Boolean,
			default: false,
		},

		/**
		 * Determine if the event has cover my call requests.
		 *
		 * @type {Boolean}
		 */
		hasRequests: {
			type: Boolean,
			default: false,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			focused: '',
		}
	},
}
</script>
