<template>
	<panel :show="show" large @close="close">
		<template #title>
			{{ hasOfficeManagerAccess ? 'Edit' : 'View' }} Event
		</template>
		<template>
			<event-tabs
				v-if="showTabs"
				class="mb-2"
				:active="tab"
				:has-requests="hasEventRequests"
				:is-recurring="isRecurring"
				@activate="activateTab"
			/>
			<edit-event-form
				v-if="tab == 'edit'"
				:id="id"
				:active-date="activeDate"
				@cancel="onClosePanel"
				@last-element-tab="onLastElementTab"
			/>
			<cover-my-call-requests
				v-if="isCoverMyCallTab"
				:requests="requests"
			/>
			<event-exceptions v-if="isExceptionTab" :event-id="id" />
		</template>
	</panel>
</template>
<script>
import Panel from '../Panel'
import { orderBy } from 'lodash'
import { mapGetters } from 'vuex'
import EventTabs from './EventTabs.vue'
import PanelMixin from '@/mixins/PanelMixin'
import EditEventForm from './EditEventForm.vue'
import EventExceptions from './EventExceptions.vue'
import CoverMyCallRequests from './CoverMyCallRequests.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CoverMyCallRequests,
		EventExceptions,
		EditEventForm,
		EventTabs,
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the event has cover my call requests.
		 *
		 * @return {Boolean}
		 */
		hasEventRequests() {
			return this.hasCoverMyCallEnabled && this.hasRequests(this.id)
		},

		/**
		 * Determine if the current tab is the CMC tab.
		 *
		 * @return {Boolean}
		 */
		isCoverMyCallTab() {
			return this.tab === 'cmc'
		},

		/**
		 * Determine if the current tab is the exceptions tab.
		 *
		 * @return {Boolean}
		 */
		isExceptionTab() {
			return this.tab === 'exception'
		},

		/**
		 * Determine if the event is recurrent.
		 *
		 * @return {Boolean}
		 */
		isRecurring() {
			const event = this.findEvent(this.id)

			return !!event?.is_recurring
		},

		/**
		 * Determine if the tabs must be shown.
		 *
		 * @return {Boolean}
		 */
		showTabs() {
			return this.hasEventRequests || this.isRecurring
		},

		...mapGetters({
			findEvent: 'events/find',
			hasOfficeManagerAccess: 'auth/hasOfficeManagerAccess',
			hasCoverMyCallEnabled: 'partners/hasCoverMyCallEnabled',
			hasRequests: 'events/hasRequests',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Set the current active tab to display.
		 *
		 * @param {String} tab
		 * @return {void}
		 */
		activateTab(tab) {
			this.tab = tab
		},

		/**
		 * Fetch cover my call request for the given event id.
		 *
		 * @return {void}
		 */
		async fetchCoverMyCallRequests() {
			try {
				const response = await this.$api
					.events()
					.requests()
					.get(this.id)

				this.requests = orderBy(
					response.get('data', []),
					['status.name', 'ends_at'],
					['desc']
				)
			} catch (e) {
				this.requests = []
			}
		},

		/**
		 * Handle the on close panel event.
		 *
		 * @return {void}
		 */
		onClosePanel() {
			this.close()
		},

		/**
		 * Handle the event id change event.
		 *
		 * @param {Number?} id
		 * @return {void}
		 */
		async onEventIdChange(id) {
			this.activateTab('edit')

			if (id && this.hasCoverMyCallEnabled) {
				await this.fetchCoverMyCallRequests()
			}
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EditPanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The active date that has been clicked.
		 *
		 * @type {Object}
		 */
		activeDate: {
			required: true,
			validator: prop =>
				['object'].includes(typeof prop) || prop === null,
		},

		/**
		 * The event's id to be edited.
		 *
		 * @type {String}
		 */
		id: {
			required: true,
			validator: prop =>
				['number', 'string'].includes(typeof prop) || prop === null,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the event object for changes.
		 *
		 * @return {void}
		 */
		id: {
			handler: 'onEventIdChange',
			immediate: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			isProviderGroupType: false,
			requests: [],
			tab: 'edit',
		}
	},
}
</script>
