<template>
	<div class="form-group">
		<label for="providerType" class="form-label">
			Type
		</label>

		<div class="relative">
			<select
				id="type"
				v-model="eventType"
				required
				:disabled="disabled"
				name="type"
				placeholder="Select a provider type"
				class="form-input"
				:class="[value ? 'text-gray-700' : 'text-gray-500']"
			>
				<option :value="null">
					Select a provider type
				</option>

				<option v-for="type in eventTypes" :key="type.id" :value="type">
					{{ type.label }}
				</option>
			</select>

			<div
				class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
			>
				<font-awesome-icon
					class="fill-current h-4 w-4 mt-4 mr-2"
					:icon="['far', 'chevron-down']"
				></font-awesome-icon>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The event type.
		 *
		 * @type {Object}
		 */
		eventType: {
			/**
			 * Get the type name.
			 *
			 * @return {String}
			 */
			get() {
				return this.value
			},

			/**
			 * Set the type name.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('input', value)
			},
		},

		...mapGetters(['eventTypes']),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EventTypeSelector',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the component should be disabled.
		 *
		 * @type {Boolean}
		 */
		disabled: {
			type: Boolean,
			default: false,
		},

		/**
		 * The event type.
		 *
		 * @type {String}
		 */
		value: {
			required: true,
			validator: prop => typeof prop === 'object' || prop === null,
		},
	},
}
</script>
