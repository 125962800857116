import moment from 'moment'
import store from '@/store'
import Application from 'App/Foundation/Application'

/**
 * Get the API Client instance from the container.
 *
 * @return {Client}
 */
export const api = () => Application.getInstance().api()

/**
 * Constant representing the time string format.
 */
const TIME_FORMAT = 'HH:mm:ss'

/**
 * Constant representing the date format.
 */
const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Constant representing the date time format.
 */
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

/**
 * Calendar event duplicator (paste event handler).
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class EventDuplicator {
	/**
	 * Create a new EventDuplicator instance.
	 *
	 * @param {Object} event
	 * @param {?String} date
	 * @param {?Number} calendarId
	 */
	constructor(event, date, calendarId) {
		this.date = date
		this.event = event
		this.exception = null
		this.calendarId = calendarId || event.calendar_id
	}

	/**
	 * Create a new event from the original duplicated event.
	 *
	 * @return {Promise}
	 */
	async duplicate() {
		const response = await store.dispatch('events/create', {
			calendarId: this.getCalendarId(),
			event: this.toObject(),
		})

		if (this.exception) {
			await api()
				.eventExceptions()
				.create(this.event.id, this.exception)
		}

		return response
	}

	/**
	 * Set the calendar id to duplicate the event to.
	 *
	 * @param {Number} id
	 * @return {EventDuplicator}
	 */
	forCalendar(id) {
		this.calendarId = id

		return this
	}

	/**
	 * Get the event's calendar id.
	 *
	 * @return {Number}
	 */
	getCalendarId() {
		return this.calendarId
	}

	/**
	 * Get the provider group event coverages as an object.
	 *
	 * @return {Object}
	 */
	getCoveragesAsObject() {
		if (!this.isProviderGroupEventType()) {
			return {}
		}

		const provider_group_id = this.event?.eventables[0].id
		const members = this.event?.eventables[0].members || []

		const coverages = members.reduce((accu, member) => {
			const coverages = member.covering_providers.map(
				(covering, index) => {
					return {
						position: index + 1,
						provider_group_id,
						provider_id: covering.id,
						covered_id: member.provider_id,
					}
				}
			)

			return accu.concat(coverages)
		}, [])

		return { coverages }
	}

	/**
	 * Get the event's eventable ids.
	 *
	 * @return {Array}
	 */
	getEventableIds() {
		return this.event.eventables.map(eventable => eventable.id)
	}

	/**
	 * Get the eventable's object key based on event type.
	 *
	 * @return {String}
	 */
	getEventableKey() {
		if (this.isProviderEventType()) {
			return 'providers'
		}

		if (this.isProviderGroupEventType()) {
			return 'provider_groups'
		}

		if (this.isLinkedCalendarEventType()) {
			return 'linked_calendars'
		}

		throw new Error('The copied event does not have an event type name.')
	}

	/**
	 * Get the event duration in minutes.
	 *
	 * @return {Number}
	 */
	getDuration() {
		return this.event.duration + this.getOffset()
	}

	/**
	 * Get the new event's ending timestsamp.
	 *
	 * @return {String}
	 */
	getEndsAt() {
		const time = moment
			.utc(this.event.starts_at)
			.local()
			.format(TIME_FORMAT)

		const start = moment(`${this.date} ${time}`, DATETIME_FORMAT).utc()

		const end = start.add(this.event.duration, 'minutes')

		return end.format(DATETIME_FORMAT)
	}

	/**
	 * Get the event type's id.
	 *
	 * @return {Number}
	 */
	getEventTypeId() {
		return this.event.type.id
	}

	/**
	 * Get the event type's name.
	 *
	 * @return {String}
	 */
	getEventTypeName() {
		return this.event.type.name
	}

	/**
	 * Get the new event's starting timestsamp.
	 *
	 * @return {String}
	 */
	getStartsAt() {
		const time = moment
			.utc(this.event.starts_at)
			.local()
			.format(TIME_FORMAT)

		const start = moment(`${this.date} ${time}`, DATETIME_FORMAT)
			.utc()
			.format(DATETIME_FORMAT)

		return start
	}

	/**
	 * Get the time offset in minutes.
	 *
	 * @return  {Number}
	 */
	getOffset() {
		const start = moment.utc(this.getStartsAt(), DATETIME_FORMAT).local()
		const end = moment.utc(this.getEndsAt(), DATETIME_FORMAT).local()

		return start.utcOffset() - end.utcOffset()
	}

	/**
	 * Determine if the event is a provider event.
	 *
	 * @return {Boolean}
	 */
	isProviderEventType() {
		return this.getEventTypeName() === 'provider'
	}

	/**
	 * Determine if the event is a provider group event.
	 *
	 * @return {Boolean}
	 */
	isProviderGroupEventType() {
		return this.getEventTypeName() === 'provider-group'
	}

	/**
	 * Determine if the event is a linked calendar event.
	 *
	 * @return {Boolean}
	 */
	isLinkedCalendarEventType() {
		return this.getEventTypeName() === 'linked-calendar'
	}

	/**
	 * Set the date to duplicate the event to.
	 *
	 * @param {String} date
	 * @return {EventDuplicator}
	 */
	onDate(date) {
		this.date = date

		return this
	}

	/**
	 * Get the new duplicated event data as an object.
	 *
	 * @return {Object}
	 */
	toObject() {
		return {
			recurrence: null,
			duration: this.getDuration(),
			calendar_id: this.getCalendarId(),
			event_type_id: this.getEventTypeId(),
			[this.getEventableKey()]: this.getEventableIds(),
			starts_at: this.getStartsAt(),
			...this.getCoveragesAsObject(),
		}
	}

	/**
	 * Create a new event exception date a recurrence cannot occur on.
	 *
	 * @param {String} date
	 * @return {EventDuplicator}
	 */
	withException(date) {
		this.exception = moment(date).format(DATETIME_FORMAT)

		return this
	}

	/**
	 * Make a new instance for the given event id.
	 *
	 * @param {Number} id
	 * @return {EventDuplicator}
	 */
	static forEventId(id) {
		const event = store.getters['events/find'](id)

		return new EventDuplicator(event)
	}
}
