<template>
	<tr>
		<td class="group-coverage-row-provider-name">
			<strong>{{ covered.full_name }}</strong>
		</td>
		<td>
			<div class="relative">
				<select
					v-model="primary"
					:disabled="!hasOfficeManagerAccess"
					name="covering_provider_id"
					placeholder="Select a provider"
					class="form-input group-coverage-table-provider-select"
					:class="[primary ? 'text-gray-700' : 'text-gray-500']"
					@change="onGroupCoverageChange"
				>
					<option :value="null" :disabled="true">
						Select a provider
					</option>
					<option
						v-for="primaryProvider in primaryOptions"
						:key="primaryProvider.id"
						:value="primaryProvider.id"
					>
						{{ primaryProvider | toProviderShortName }}
					</option>
				</select>
				<div
					class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
				>
					<font-awesome-icon
						class="fill-current h-4 w-4 mt-4 mr-2"
						:icon="['far', 'chevron-down']"
					>
					</font-awesome-icon>
				</div>
			</div>
		</td>
		<td>
			<div v-if="!hasExternalSelected" class="relative">
				<select
					v-model="secondary"
					:disabled="!hasOfficeManagerAccess"
					name="covering_secondary_provider_id"
					placeholder="Select a provider"
					class="form-input group-coverage-table-provider-select"
					:class="[secondary ? 'text-gray-700' : 'text-gray-500']"
					@change="onGroupCoverageChange"
				>
					<option :value="null">
						Select a provider
					</option>

					<option
						v-for="provider in secondaryOptions"
						:key="provider.id"
						:value="provider.id"
					>
						{{ provider | toProviderShortName }}
					</option>
				</select>
				<div
					class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
				>
					<font-awesome-icon
						class="fill-current h-4 w-4 mt-4 mr-2"
						:icon="['far', 'chevron-down']"
					>
					</font-awesome-icon>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
import { sortBy } from 'lodash'
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get all internal/external providers.
		 *
		 * @return {Array}
		 */
		allProviders() {
			return sortBy(this.getAllProviders || [], 'display_name')
		},

		/**
		 * Get the current coverages.
		 *
		 * @return {Array}
		 */
		coverages() {
			const coverages = []

			if (this.shouldPushPrimaryToCoverages) {
				coverages.push({
					covered_id: this.covered.id,
					position: 1,
					provider_group_id: this.groupId,
					provider_id: this.primary || this.covered.id,
				})
			}

			if (this.shouldPushSecondaryToCoverages) {
				coverages.push({
					covered_id: this.covered.id,
					position: 2,
					provider_group_id: this.groupId,
					provider_id: this.secondary,
				})
			}

			return coverages
		},

		/**
		 * Determine if the member have covering providers.
		 *
		 * @return {Boolean}
		 */
		hasCoveringProviders() {
			if (!this.member.covering_providers) {
				return false
			}

			return this.member.covering_providers.length > 0
		},

		/**
		 * Determine if an external provider was selected.
		 *
		 * @return {Boolean}
		 */
		hasExternalSelected() {
			const provider = this.allProviders.find(
				provider => provider.id === this.primary
			)

			return provider?.type === 'external'
		},

		/**
		 * Get the current event's assigned provider group.
		 *
		 * @return {?Object}
		 */
		group() {
			if (!this.event || !this.event.eventables.length) {
				return null
			}

			return this.event.eventables[0]
		},

		/**
		 * Get the current provider group's members.
		 *
		 * @return {Array}
		 */
		groupMembers() {
			return this.group?.members || []
		},

		/**
		 * Get the current provider group provider's membership.
		 *
		 * @return {?Object}
		 */
		member() {
			return this.groupMembers.find(member => {
				return member.provider_id === this.covered.id
			})
		},

		/**
		 * The primary options to be shown.
		 *
		 * @return {Array}
		 */
		primaryOptions() {
			return this.allProviders.filter(provider => {
				return provider.id !== this.secondary
			})
		},

		/**
		 * The secondary options to be shown.
		 *
		 * @return {Array}
		 */
		secondaryOptions() {
			return this.allProviders.filter(provider => {
				if (provider.type !== 'internal') {
					return false
				}

				if (!this.primary) {
					return provider.id !== this.covered.id
				}

				return provider.id !== this.primary
			})
		},

		/**
		 * Determine if the primary provider should be push into the coverages array.
		 *
		 * @return {Boolean}
		 */
		shouldPushPrimaryToCoverages() {
			if (this.primary !== this.covered.id) {
				return !!this.primary
			}

			return !!this.secondary
		},

		/**
		 * Determine if the secondary provider should be push into the coverages array.
		 *
		 * @return {Boolean}
		 */
		shouldPushSecondaryToCoverages() {
			if (!this.secondary) {
				return false
			}

			return this.primary !== this.secondary
		},

		...mapGetters({
			getAllProviders: 'providers/all',
			hasOfficeManagerAccess: 'auth/hasOfficeManagerAccess',
		}),

		...mapGetters('partners', {
			partner: 'active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on change event.
		 *
		 * @return {void}
		 */
		onGroupCoverageChange() {
			this.$emit('updated', {
				covered_id: this.covered.id,
				coverages: this.coverages,
			})
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'GroupCoverageRow',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The covered provider.
		 *
		 * @type {Object}
		 */
		covered: {
			type: Object,
			required: true,
		},

		/**
		 * The selected provider group group.
		 *
		 * @type {Number}
		 */
		groupId: {
			type: Number,
			required: true,
		},

		/**
		 * The row's member.
		 *
		 * @type {Object}
		 */
		event: {
			type: Object,
			default: () => ({}),
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the primary provider for changes.
		 *
		 * @return {void}
		 */
		primary() {
			if (
				(this.primary && this.primary === this.secondary) ||
				this.hasExternalSelected
			) {
				this.secondary = null
			}

			if (this.member?.covering_providers.length === 1) {
				this.onGroupCoverageChange()
			}
		},

		/**
		 * Watch the secondary provider for changes.
		 *
		 * @return {void}
		 */
		secondary() {
			if (!this.primary && this.secondary !== this.covered.id) {
				this.primary = this.covered.id
			}

			if (this.secondary && this.primary === this.secondary) {
				this.primary = null
			}

			if (this.member?.covering_providers.length > 1) {
				this.onGroupCoverageChange()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		if (!this.primary && this.covered.id) {
			this.primary = this.covered.id
		}

		if (!this.member || !this.hasCoveringProviders) {
			return
		}

		this.primary = this.member.covering_providers[0]?.id || null
		this.secondary = this.member.covering_providers[1]?.id || null
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			primary: null,
			secondary: null,
		}
	},
}
</script>
