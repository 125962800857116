<template>
	<div v-if="show" class="dialog">
		<div class="dialog-container" role="dialog" aria-modal="true">
			<div class="dialog-header">
				<h3 class="dialog-title">
					<slot name="title" />
				</h3>
				<button
					ref="close"
					type="button"
					class="bg-gray-200 rounded-full h-6 w-6 flex items-center justify-center focus:bg-gray-300 focus:outline-none "
					@click="close"
					@keydown.tab.shift.prevent="onFirstElementShiftTab"
				>
					<span class="sr-only">
						Close the confirmation dialog
					</span>
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>
			<div class="dialog-body">
				<div class="dialog-body-message">
					<slot />
				</div>
			</div>

			<div class="dialog-footer">
				<button
					type="button"
					class="btn btn-danger w-50 mr-2"
					data-cy="confirmation-cancel-btn"
					@click.prevent="close"
				>
					<div class="btn-ripple" />
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'times-octagon']"
					/>
					<span class="btn-label">
						Cancel
					</span>
				</button>
				<button
					ref="last"
					data-cy="confirmation-btn"
					class="btn btn-outline-success w-50 ml-2"
					@click.prevent="onConfirm"
					@keydown.tab="onLastElementTab"
				>
					<div class="btn-ripple" />
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'check']"
						aria-hidden="true"
					/>
					<span class="btn-label">
						OK
					</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import PanelMixin from '@/mixins/PanelMixin'

export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			this.$emit('cancel')
		},

		/**
		 * Get the close element reference.
		 *
		 * @return {VueComponent|HtmlElement}
		 */
		getCloseReference() {
			return this.$refs.close
		},

		/**
		 * Handle the on confirm event.
		 *
		 * @return {void}
		 */
		onConfirm() {
			this.$emit('confirmed')
		},

		/**
		 * Handle the shift+tab key being pressed on the first element.
		 *
		 * @param {KeyboardEvent} event
		 * @return {void}
		 */
		onFirstElementShiftTab() {
			const last = this.$refs.last

			if (last && last.$el) {
				return last.$el.focus()
			}

			last.focus()
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ConfirmationDialog',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine whether the modal is visible.
		 *
		 * @type {Boolean}
		 */
		show: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
