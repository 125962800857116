/*
|--------------------------------------------------------------------------
| Provider's colors
|--------------------------------------------------------------------------
|
| The constants below represent the colors that can be used to identify
| a provider quicky on a calendar while viewing/editing a schedule.
|
*/
export const BLUE = {
	name: 'blue',
	label: 'Blue',
	code: 'rgb(49, 130, 206)',
	bgClass: 'provider-bg-blue',
	background: 'rgb(190, 227, 248)',
	border: 'rgb(99, 179, 237)',
	text: 'rgb(42, 67, 101)',
}

export const BROWN = {
	name: 'brown',
	label: 'Brown',
	code: 'rgb(116, 66, 16)',
	bgClass: 'provider-bg-brown',
	background: 'rgb(214, 158, 46)',
	border: 'rgb(151, 91, 22)',
	text: 'rgb(59, 33, 8)',
}

export const GRAY = {
	name: 'gray',
	label: 'Gray',
	code: 'rgb(74, 85, 104)',
	bgClass: 'provider-bg-gray',
	background: 'rgb(237, 242, 247)',
	border: 'rgb(203, 213, 224)',
	text: 'rgb(26, 32, 44)',
}

export const GREEN = {
	name: 'green',
	label: 'Green',
	code: 'rgb(56, 161, 105)',
	bgClass: 'provider-bg-green',
	background: 'rgb(198, 246, 213)',
	border: 'rgb(104, 211, 145)',
	text: 'rgb(34, 84, 61)',
}

export const ORANGE = {
	name: 'orange',
	label: 'Orange',
	code: 'rgb(245, 131, 10)',
	bgClass: 'provider-bg-orange',
	background: 'rgb(252, 211, 167)',
	border: 'rgb(249, 173, 93)',
	text: 'rgb(108, 58, 4)',
}

export const PINK = {
	name: 'pink',
	label: 'Pink',
	code: 'rgb(237, 100, 166)',
	bgClass: 'provider-bg-pink',
	background: 'rgb(254, 215, 226)',
	border: 'rgb(246, 135, 179)',
	text: 'rgb(112, 36, 89)',
}

export const PURPLE = {
	name: 'purple',
	label: 'Purple',
	code: 'rgb(98, 0, 238)',
	bgClass: 'provider-bg-purple',
	background: 'rgb(233,216,253)',
	border: 'rgb(183,148,244)',
	text: 'rgb(68,51,122)',
}

export const RED = {
	name: 'red',
	label: 'Red',
	code: 'rgb(229, 62, 62)',
	bgClass: 'provider-bg-red',
	background: 'rgb(254,215,215)',
	border: 'rgb(252,129,129)',
	text: 'rgb(116,42,42)',
}

export const TEAL = {
	name: 'teal',
	label: 'Teal',
	code: 'rgb(49, 151, 149)',
	bgClass: 'provider-bg-teal',
	background: 'rgb(190, 227, 248)',
	border: 'rgb(38, 198, 218)',
	text: 'rgb(0, 97, 100)',
}

export const TURQUOISE = {
	name: 'turquoise',
	label: 'Turquoise',
	code: 'rgb(0, 188, 212)',
	bgClass: 'provider-bg-turquoise',
	background: 'rgb(128, 222, 234)',
	border: 'rgb(38, 198, 218)',
	text: 'rgb(0, 96, 100)',
}

export const INDIGO = {
	name: 'indigo',
	label: 'Indigo',
	code: 'rgb(102, 126, 234)',
	bgClass: 'provider-bg-indigo',
	background: 'rgb(195, 218, 254)',
	border: 'rgb(127, 156, 245)',
	text: 'rgb(60, 54, 107)',
}

export const YELLOW = {
	name: 'yellow',
	label: 'Yellow',
	code: 'rgb(236, 201, 75)',
	bgClass: 'provider-bg-yellow',
	background: 'rgb(254, 252, 191)',
	border: 'rgb(246, 224, 94)',
	text: 'rgb(116, 66, 16)',
}

/**
 * The color a provider should default to if their color is not found.
 *
 * @type {Object}
 */
export const defaultColor = GRAY

/**
 * The available colors.
 *
 * @type {Array}
 */
export default [
	BLUE,
	BROWN,
	INDIGO,
	GRAY,
	GREEN,
	ORANGE,
	PINK,
	PURPLE,
	RED,
	TEAL,
	TURQUOISE,
	YELLOW,
]
