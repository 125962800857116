/**
 * Timezone abbreviations.
 *
 * @type {Object}
 */
export default {
	'America/Adak': 'ET',
	'America/Anchorage': 'AKT',
	'America/Boise': 'MT',
	'America/Chicago': 'CT',
	'America/Denver': 'MT',
	'America/Detroit': 'ET',
	'America/Indiana/Indianapolis': 'ET',
	'America/Indiana/Knox': 'CT',
	'America/Indiana/Marengo': 'ET',
	'America/Indiana/Petersburg': 'ET',
	'America/Indiana/Tell_City': 'CT',
	'America/Indiana/Vevay': 'ET',
	'America/Indiana/Vincennes': 'ET',
	'America/Indiana/Winamac': 'ET',
	'America/Juneau': 'AKT',
	'America/Kentucky/Louisville': 'ET',
	'America/Kentucky/Monticello': 'ET',
	'America/Los_Angeles': 'PT',
	'America/Menominee': 'CT',
	'America/Metlakatla': 'AKT',
	'America/New_York': 'ET',
	'America/Nome': 'AKT',
	'America/North_Dakota/Beulah': 'CT',
	'America/North_Dakota/Center': 'CT',
	'America/North_Dakota/New_Salem': 'CT',
	'America/Phoenix': 'MT',
	'America/Sitka': 'AKT',
	'America/Yakutat': 'AKT',
	'Pacific/Honolulu': 'HST',
}
