import Event from 'App/Models/Event'
import colors, { defaultColor } from '@/config/colors'

/**
 * Calendar event model.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default class FullCalendarEvent extends Event {
	/**
	 * Get the event's color background color code.
	 *
	 * @return {String}
	 */
	getColor() {
		if (this.isProviderGroupType()) {
			return defaultColor
		}

		const provider = this.getProvider()

		if (!provider) {
			return defaultColor
		}

		const color = colors.find(color => provider.color === color.name)

		return color || defaultColor
	}

	/**
	 * Get the event's display type.
	 *
	 * @return {String}
	 */
	getDisplayType() {
		if (this.isAllDay()) {
			return 'block'
		}

		if (this.isMultipleDays()) {
			return 'list-item'
		}

		return 'block'
	}

	/**
	 * Get event's exceptions.
	 *
	 * @return {Array}
	 */
	getExceptions() {
		return this.getAttribute('exceptions', []).map(
			exception => exception.starts_at
		)
	}

	/**
	 * Get the formatted RRule object.
	 *
	 * @return {?Object}
	 */
	getRecurrence() {
		const recurrence = this.getAttribute('recurrence')

		return {
			...recurrence,
			dtstart: this.getAttribute('starts_at'),
		}
	}
	getEventStartData() {
		return this.getAttribute('starts_at')
	}

	/**
	 * Get the secondary provider's color code.
	 *
	 * @return {String}
	 */
	getSecondaryColor() {
		if (!this.hasSecondary()) {
			return
		}

		const secondary = this.getSecondary()

		if (!secondary) {
			return defaultColor
		}

		const color = colors.find(color => secondary.color === color.name)

		return color || defaultColor
	}

	/**
	 * Get the event's title.
	 *
	 * @return {String}
	 */
	getTitle() {
		if (this.isProviderGroupType()) {
			return this.getProviderGroup().name
		}

		if (this.isLinkedCalendarType()) {
			const calendar = this.getLinkedCalendar()

			return (
				this._findPartner(calendar?.partner_id)?.name ||
				'Partner Removed'
			)
		}

		const provider = this.getPrimary()

		if (provider.type === 'internal') {
			return `(P) ${provider.display_name}`
		}

		if (provider.type === 'external') {
			return provider.display_name
		}

		return 'Not Found'
	}

	/**
	 * Get the secondary provider's event title.
	 *
	 * @return {String}
	 */
	getSecondaryTitle() {
		if (!this.hasSecondary()) {
			return
		}

		return `(S) ${this.getSecondary().display_name}`
	}

	/**
	 * Determine if the event has recurrence.
	 *
	 * @return {Boolean}
	 */
	hasRecurrence() {
		return !!this.getAttribute('recurrence')
	}

	/**
	 * Get the instance as a definition object.
	 *
	 * @return {Object}
	 */
	toObject() {
		const recurrence = {}
		const color = this.getColor()
		const secondaryColor = this.getSecondaryColor()

		if (this.hasRecurrence()) {
			recurrence.rrule = this.getRecurrence()
			recurrence.exdate = this.getExceptions()
		}

		return {
			...super.toObject(),
			...recurrence,
			backgroundColor: color.background,
			borderColor: color.border,
			display: this.getDisplayType(),
			displayColor: color,
			displayEventEnd: true,
			duration: {
				minutes: this.getAttribute('duration'),
			},
			end: this.getAttribute('ends_at'),
			hasRequests: this.hasRequests(),
			isAllDay: this.isAllDay(),
			linkedPartnerCalendar: {
				name: this.isLinkedCalendarType()
					? this.getLinkedPartnerCalendarName()
					: null,
				color: defaultColor,
			},
			multipleDays: this.isMultipleDays(),
			secondary: {
				title: this.getSecondaryTitle(),
				color: secondaryColor,
			},
			start: this.getAttribute('starts_at'),
			eventStartDate: this.getEventStartData(),
			textColor: color.text,
			title: this.getTitle(),
		}
	}
}
