<template>
	<div>
		<div
			class="context-menu"
			:style="contextMenu"
			:class="{ active: show }"
		>
			<button v-if="copy" class="item" @click.prevent="onCopyClick">
				<font-awesome-icon
					aria-hidden="true"
					class="btn-icon mr-2"
					:icon="['fas', 'clone']"
				/>
				Copy
			</button>
			<button
				v-if="paste && event && eventId"
				class="item"
				@click.prevent="onPasteClick"
			>
				<font-awesome-icon
					aria-hidden="true"
					class="btn-icon mr-2"
					:icon="['fas', 'paste']"
				/>
				Paste
			</button>
		</div>
	</div>
</template>

<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The context menu style for positioning it.
		 *
		 * @return {Object}
		 */
		contextMenu() {
			return {
				top: `${this.top}px`,
				left: this.left ? `${this.left}px` : null,
				right: this.right ? `${this.right}px` : null,
			}
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Display only the copy button.
		 *
		 * @return {void}
		 */
		displayOnlyCopyButton() {
			this.copy = true
			this.paste = false
		},

		/**
		 * Display only the paste button.
		 *
		 * @return {void}
		 */
		displayOnlyPasteButton() {
			this.copy = false
			this.paste = true
		},

		/**
		 * Position the context menu to the left or the right side.
		 *
		 * @param {Number} clickX
		 * @param {Number} clickY
		 * @return {void}
		 */
		positionMenuFromClick(clickX, clickY) {
			const menuWidth = 160

			this.left = null
			this.right = null
			this.top = clickY - window.pageYOffset

			if (clickX + menuWidth >= window.innerWidth) {
				return (this.right = window.innerWidth - clickX)
			}

			this.left = clickX - window.pageXOffset
		},

		/**
		 * Handle the click event listener.
		 *
		 * @return {void}
		 */
		onClickOut() {
			this.show = false
		},

		/**
		 * Handle the on context menu event listener.
		 *
		 * @return {void}
		 */
		onContextMenuClick({ info, event, jsEvent }) {
			if (!jsEvent) {
				this.event = info.event.id
			}

			this.displayOnlyCopyButton()

			const evt = event || jsEvent

			evt.preventDefault()

			this.positionMenuFromClick(
				evt.pageX || evt.clientX,
				evt.pageY || evt.clientY
			)

			this.show = true
		},

		/**
		 * Handle the on copy click event.
		 *
		 * @return {void}
		 */
		onCopyClick() {
			this.$emit('copy', this.event)
		},

		/**
		 * Handle the a calendar day click event.
		 *
		 * @param {Object} event
		 */
		onDayClick(event) {
			if (!event) {
				return
			}

			this.displayOnlyPasteButton()

			const evt = event.jsEvent

			evt.stopImmediatePropagation()

			this.positionMenuFromClick(
				evt.pageX || evt.clientX,
				evt.pageY || evt.clientY
			)

			this.show = true
		},

		/**
		 * Handle the on paste click event.
		 *
		 * @return {void}
		 */
		onPasteClick() {
			this.$emit('paste')
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ContextMenu',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The event id to copy.
		 *
		 * @type {Number}
		 */
		eventId: {
			required: true,
			validator: prop => typeof prop === 'number' || prop === null,
		},
	},

	/**
	 * The component's before create lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app
			.off('event:contextmenu', this.onContextMenuClick)
			.off('day:contextmenu', this.onDayClick)

		document.removeEventListener('click', this.onClickOut)
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.$app
			.on('event:contextmenu', this.onContextMenuClick)
			.on('day:contextmenu', this.onDayClick)

		document.addEventListener('click', this.onClickOut)
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			copy: false,
			event: null,
			left: 0,
			paste: false,
			right: 0,
			show: false,
			top: 0,
		}
	},
}
</script>
