<template>
	<button
		role="tab"
		type="button"
		:tabindex="tabIndex"
		class="tab-bar-item"
		:class="{
			'is-active': isActive,
			'is-focused': isFocused,
		}"
		:aria-selected="isActive.toString()"
	>
		<span class="tab-bar-item-content">
			<font-awesome-icon
				v-if="icon.length"
				class="tab-bar-item-icon"
				:icon="icon"
				aria-hidden="true"
			/>
			<span class="tab-bar-item-text">
				<slot />
			</span>
		</span>
		<span class="tab-bar-item-indicator">
			<span class="tab-indicator-underline"></span>
		</span>
		<span class="tab-bar-item-bg"></span>
	</button>
</template>
<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the tab is active.
		 *
		 * @return {Boolean}
		 */
		isActive() {
			return this.current === this.name
		},

		/**
		 * Determine if the tab item is focused.
		 *
		 * @return {Boolean}
		 */
		isFocused() {
			return this.focused === this.name
		},

		/**
		 * Get the tab index value for the button.
		 *
		 * @return {String}
		 */
		tabIndex() {
			return this.isActive ? '0' : '-1'
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Determine if the button should maintain the browser's focus.
		 *
		 * @return {Boolean}
		 */
		shouldFocus() {
			return this.isFocused && document.activeElement !== this.$el
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'TabBarItem',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current active tab name.
		 *
		 * @type {String}
		 */
		current: {
			type: String,
			default: '',
		},

		/**
		 * The current focused tab name.
		 *
		 * @param {String}
		 */
		focused: {
			type: String,
			default: '',
		},

		/**
		 * The font awesome icon array.
		 *
		 * @type {Array}
		 */
		icon: {
			type: Array,
			default() {
				return []
			},
		},

		/**
		 * The tab name used to determine if the tab is active/focused.
		 *
		 * @type {String}
		 */
		name: {
			type: String,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the focused prop to toggle the browser's focus.
		 *
		 * @return {void}
		 */
		isFocused() {
			if (this.shouldFocus()) {
				this.$el.focus()
			}
		},
	},
}
</script>
